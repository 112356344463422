import { TileDataInterface } from "../interfaces/TileInterface";
import { collectedApps } from "./collectedApps";
import { FavData } from "./searchStore";

export const dataParser = (data: FavData | any) => {
    const isOldData = data?.favApps?.some((item: any) => !item.target);
    const dataToModify = isOldData ? data.favApps : data.appData;
    if (data.appData || isOldData) {
        const favApps: TileDataInterface[] = [];
        dataToModify.forEach(
            (app: {
                alias: string;
                cat: string | undefined;
                status: boolean;
            }) => {
                if (app.status) {
                    const foundApp: TileDataInterface | undefined =
                        collectedApps.find(
                            (collectedApp) => collectedApp.alias === app.alias
                        );
                    if (foundApp) {
                        favApps.push({
                            ...foundApp,
                            status: true,
                            cat: app.cat || undefined,
                        });
                    }
                }
            }
        );
        return {
            favApps,
            favTabs: data.tabData || [
                { active: true, count: 1, name: "Favourite" },
            ],
            favLang: data.lang,
            tourTaken: data.tourTaken,
        };
    } else {
        return data;
    }
};
