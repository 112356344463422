import { memo } from "react";
import AppIcon from "../../../assets/el-search-images/app.svg";

interface IconComponentProps
    extends React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    > {}

function IconComponent(props: IconComponentProps) {
    const handleImageError = (
        e: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = AppIcon;
    };

    return <img onError={handleImageError} {...props} />;
}

export default memo(IconComponent);
