import axios from "axios";

export class OAuth2Token {
    private authUrl!: string;
    private authTokenUrl!: string;
    private redirectUri!: string;
    private clientId!: string;
    private clientSecret!: string;
    private scope!: string;

    setAuthUrl(authUrl: string) {
        this.authUrl = authUrl;
        return this;
    }

    setAuthTokenUrl(authTokenUrl: string) {
        this.authTokenUrl = authTokenUrl;
        return this;
    }

    setRedirectUri(redirectUri: string) {
        this.redirectUri = redirectUri;
        return this;
    }

    setClientId(clientId: string) {
        this.clientId = clientId;
        return this;
    }

    setClientSecret(clientSecret: string) {
        this.clientSecret = clientSecret;
        return this;
    }

    setScope(scope: string) {
        this.scope = scope;
        return this;
    }

    getAuthorizationUrl = (): string => {
        const params = new URLSearchParams({
            response_type: "code",
            client_id: this.clientId,
            redirect_uri: this.redirectUri,
            scope: this.scope
        });

        return `${this.authUrl}?${params.toString()}`;
    };

    getAccessToken = async (
        authorizationCode: string
    ): Promise<any> => {
        const response = await axios.post(
            this.authTokenUrl,
            new URLSearchParams({
                grant_type: "authorization_code",
                code: authorizationCode,
                redirect_uri: this.redirectUri,
                client_id: this.clientId,
                client_secret: this.clientSecret
            }),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
        return response.data;
    };
}
