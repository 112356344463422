import { emptyDataObject, SourceInfo } from "../info";
import { uppercaseFirstLetterOnly } from "../../util/strings";
import { dateFormat } from "../../util/date";

export const pageInfo: SourceInfo = {
    match: "page",
    dropDown: undefined,
    titleAndTarget: () => {
        throw new Error("Not implemented");
    },
    makeDataObject: (result) => ({
        ...emptyDataObject,
        id: result.id,
        index: result.index,
        icon: "haiilo",
        link: `https://dev-connect.eon.com/${result?.target?.name}s/${result?.target?.params?.slug}`,
        name: result.title,
        source: "Connect - Page",
        status: uppercaseFirstLetterOnly(result.visibility),
        desc: result.description.unknown,
        createdAt: dateFormat(result.createdDate),
    }),
};
