import { MakeQueryForEsFn } from "./search";

export const makeKnnQuery: MakeQueryForEsFn = (
    resultSize: number,
    searchTerm: string,
    dateFilters: any
) => ({
    size: resultSize,
    _source: {
        excludes: [
            "full_text_embeddings",
            "full_text",
            "file",
            "pdf file path",
            "pdf",
        ],
    },
    knn: {
        field: "full_text_embeddings",
        query_vector_builder: {
            text_embedding: {
                model_id: ".multilingual-e5-small_linux-x86_64",
                model_text: searchTerm,
            },
        },
        k: resultSize,
        num_candidates: resultSize + 10,
    },
    ...(dateFilters.length > 0 && { query: { bool: { ...dateFilters } } }),
    from: 0,
});
