import { Env } from "./ConfigInterface";

export type FeatureFlagType = {
    enableDefaultTileAppFeature: boolean;
};

const featureFlagConfig: Record<Env, FeatureFlagType> = {
    local: {
        enableDefaultTileAppFeature: false,
    },
    dev: {
        enableDefaultTileAppFeature: false,
    },
    qa: {
        enableDefaultTileAppFeature: false,
    },
    pp: {
        enableDefaultTileAppFeature: false,
    },
    prod: {
        enableDefaultTileAppFeature: false,
    },
};

export default featureFlagConfig;
