import "./TimePicker.scss";
import {
    EonUiDropdown,
    EonUiDropdownOption,
} from "@eon-ui/eon-ui-components-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useRoomBookingStore from "../../store/roomBookingStore";
import { getTimeStrings } from "./timeStrings";

function preselectNearestTime(timeStrings: string[]) {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();

    const timeString = timeStrings.find((timeString) => {
        const [hour, minute] = timeString.split(":");
        if (
            parseInt(hour) === currentHour + 1 &&
            parseInt(minute) < currentMinutes
        ) {
            return true;
        }
        return (
            parseInt(hour) === currentHour && parseInt(minute) >= currentMinutes
        );
    });

    if (!timeString) {
        throw Error(
            "There should be atleast one value matching the condition above"
        );
    }

    return timeString;
}

type TimePickerProps = {
    timeChangedHandler: (timeValue: string) => void;
};

function TimePicker({ timeChangedHandler }: TimePickerProps) {
    const { timePickerVal } = useRoomBookingStore();
    const { t } = useTranslation("room-booking");
    let timeStrings = getTimeStrings();
    const nearestTime = preselectNearestTime(timeStrings);
    timeStrings = getTimeStrings(nearestTime);
    useEffect(() => {
        timeChangedHandler(nearestTime);
    }, []);

    return (
        <>
        <EonUiDropdown
            data-testid="timePickerRoot"
            placeholder="00:00"
            onValueChanged={(e) => timeChangedHandler(e.target.value)}
            value={timePickerVal}
            name="dropdown"
            size="small"
            height="256px"
            label={t("startingTime")}
            labelOutside={true}
        >
            {timeStrings.map((item) => (
                <EonUiDropdownOption key={item} value={item} label={item} />
            ))}
        </EonUiDropdown>
        </>
        
    );
}

export default TimePicker;
