import {Env} from "./ConfigInterface";

const gingcoClientId =
    process.env.REACT_APP_GINGCO_CLIENT_ID || "me-at-eon-test";

export const AGGREGATION_SIZE: number = 1000;

export type OtherConfig = {
    snowClientId: string;
    appIndex: string;
    indicies: string;
    gingcoClientId: string;
    AiAccess: string[];
    snowClientIdLiveAgentChat: string;
    genAiApiBaseUrl: string;
    meAtEonContactEmail: string;
    showItOrderFilter: boolean,
    azureBlobUrl: string;
}

const otherConfigs: Record<Env, OtherConfig> = {
    local: {
        snowClientId: "5ab0593c7a9444cb845e8b13f7610baf",
        appIndex: "apps-dev",
        indicies:
            "jira-prod,confluence-prod,gitlab-prod,mygenius-prod,it-order-prod",
        gingcoClientId,
        AiAccess: ["ME8EON_POC_USERS", "ME8EON_POC_MANAGERS"],
        snowClientIdLiveAgentChat: "65cd92c89b65465884bc3d0395ecef55",
        genAiApiBaseUrl: "https://genai-api.eon.com",
        meAtEonContactEmail: "me@eon.com",
        showItOrderFilter: true,
        azureBlobUrl: "https://snowkbforme8eon.blob.core.windows.net"
    },
    dev: {
        snowClientId: "5ab0593c7a9444cb845e8b13f7610baf",
        appIndex: "apps-dev",
        indicies:
            "jira-prod,confluence-prod,gitlab-prod,mygenius-prod,it-order-prod",
        gingcoClientId,
        AiAccess: ["ME8EON_POC_USERS", "ME8EON_POC_MANAGERS"],
        snowClientIdLiveAgentChat: "65cd92c89b65465884bc3d0395ecef55",
        genAiApiBaseUrl: "https://genai-api.eon.com",
        meAtEonContactEmail: "me@eon.com",
        showItOrderFilter: true,
        azureBlobUrl: "https://snowkbforme8eon.blob.core.windows.net"
    },
    qa: {
        snowClientId: "25a0dab6ab914e7a962cff0f47ceb0df",
        appIndex: "apps-dev",
        indicies:
            "jira-prod,confluence-prod,gitlab-prod,mygenius-prod,it-order-prod",
        gingcoClientId,
        AiAccess: ["ME8EON_POC_USERS", "ME8EON_POC_MANAGERS"],
        snowClientIdLiveAgentChat: "d1588b7cc9654195bb724e2efa5176e4",
        genAiApiBaseUrl: "https://genai-api.eon.com",
        meAtEonContactEmail: "me@eon.com",
        showItOrderFilter: true,
        azureBlobUrl: "https://snowkbforme8eon.blob.core.windows.net"
    },
    pp: {
        snowClientId: "25a0dab6ab914e7a962cff0f47ceb0df",
        appIndex: "apps-prod",
        indicies: "jira-prod,confluence-prod,gitlab-prod,mygenius-prod",
        gingcoClientId,
        AiAccess: ["APL213643-me8eon-Ai-assistance", "APL213643-me8eon-User"],
        snowClientIdLiveAgentChat: "d1588b7cc9654195bb724e2efa5176e4",
        genAiApiBaseUrl: "https://genai-api.eon.com",
        meAtEonContactEmail: "me@eon.com",
        showItOrderFilter: false,
        azureBlobUrl: "https://snowkbforme8eon.blob.core.windows.net"
    },
    prod: {
        snowClientId: "620785b3bab846e8acaaefd44bf53234",
        appIndex: "apps-prod",
        indicies: "jira-prod,confluence-prod,gitlab-prod,mygenius-prod",
        gingcoClientId,
        AiAccess: ["APL213643-me8eon-User"],
        snowClientIdLiveAgentChat: "f41ea50929e94393b7c105b35e996c7d",
        genAiApiBaseUrl: "https://genai-api.eon.com",
        meAtEonContactEmail: "me@eon.com",
        showItOrderFilter: false,
        azureBlobUrl: "https://snowkbforme8eon.blob.core.windows.net"
    },
};

export default otherConfigs;
