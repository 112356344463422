import { EonUiButton } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import SearchFiltersRight from "./SearchFiltersRight";
import { FilterSetters } from "./elsearch.domain";
import "./SearchFiltersRight.scss";
import { AdditionalFilters, DataSourceFilter, SelectedMetadataFilters } from "../../interfaces/ElasticSearchInterface";

type SearchFiltersRightModalProps = {
    setSearchFiltersRightModal: (value: boolean) => void;
    setters: FilterSetters;
    searchIndex: string[];
    additionalFilters: AdditionalFilters;
    metadataFilters: SelectedMetadataFilters;
    dataSourceFilters: DataSourceFilter[];
    filterCount: number;
};

const SearchFiltersRightModal = ({setSearchFiltersRightModal, ...props}: SearchFiltersRightModalProps) => {
    const { t } = useTranslation();
    return (
        <div className="search-filters-modal-container">
            <SearchFiltersRight {...props} />
            <div className="btn">
                <div className="cancel">
                    <EonUiButton rank="secondary" text={t("close")} onClick={() => setSearchFiltersRightModal(false)}></EonUiButton>
                </div>
            </div>
        </div>
    );
};

export default SearchFiltersRightModal;