import { create } from "zustand";
import { combine } from "zustand/middleware";

// Define HeaderState interface
interface HeaderState {
    language: string;
    setLanguage: (lang: string) => void;
}

const useHeaderStore = create<HeaderState>((set) => ({
    language: "en",
    setLanguage: (lang) => set({ language: lang }),
}));

// Combine only language and favData states
import useSearchStore from "./searchStore";

const useCombinedStore = create(
    combine(
        {
            language: useHeaderStore.getState().language,
            favData: useSearchStore.getState().favData,
        },
        () => ({
            setLanguage: useHeaderStore.getState().setLanguage,
            updateFavObject: useSearchStore.getState().updateFavObject,
        })
    )
);

export { useHeaderStore, useCombinedStore };
