import React, { useEffect } from "react";
import "./HeroComponent.scss";
import { EonUiButton } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../MsalContext";

const HeroComponent: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { login: handleLogin, account } = useAuth();

    useEffect(() => {
        if (account) {
            navigate("/tile");
        }
    }, [account]);

    return (
        <div className="hero-wrapper" data-testid="heroComponent">
            <div className="bg-img-hero"></div>
            <div className="content-wrapper">
                <div className="welcome-content">
                    <h1 className="headline-font-welcome">
                        {t("headerData1")}
                    </h1>
                    <h1 className="headline-font-welcome">
                        {t("headerData2")}
                    </h1>
                    <div className="login-form">
                        <div className="login-button-align">
                            <EonUiButton
                                placeholder="login-btn"
                                id="enableLogin"
                                className="login-button-between-space-tab-laptop"
                                onClick={handleLogin}
                                text={t("loginLable")}
                            ></EonUiButton>
                        </div>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default HeroComponent;
