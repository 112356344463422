import { SearchFn } from "./search";
import { ESApiFinalResponseInterface } from "../interfaces/ElasticSearchInterface";
import { sanitize } from "../util/strings";
import { PublicClientApplication } from "@azure/msal-browser";

const searchBodyForJql = (query: string) => {
    const q = sanitize(query);
    return {
        jql: `summary ~ "${q}" OR description ~ "${q}" OR comment ~ "${q}"`,
        fields: ["summary", "status", "assignee"],
        startAt: 0,
        maxResults: 20,
    };
};

export function defaultJiraTokenFn(
    scopes: string[],
    msalInstance: PublicClientApplication
): () => Promise<string> {
    return async () => {
        const account = msalInstance.getAllAccounts()[0];
        if (!account) throw new Error("No account found");
        try {
            // First, try to acquire the token silently
            const response = await msalInstance.acquireTokenSilent({
                scopes,
                account,
            });
            return response.accessToken;
        } catch (error) {
            // If silent token acquisition fails, pop up the login window
            const response = await msalInstance.acquireTokenPopup({
                scopes,
                account,
            });
            return response.accessToken;
        }
    };
}

export function jiraSearch(
    token: () => Promise<string>
): SearchFn<ESApiFinalResponseInterface> {
    return async (query) => {
        const headers = {
            Authorization: `Bearer ${await token()}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        };
        const resp = await fetch(`https://jira.eon.com/rest/api/2/search`, {
            method: "POST",
            headers,
            body: JSON.stringify(searchBodyForJql(query.searchTerm)),
        });
        if (!resp.ok)
            throw new Error(
                `Failed to search: ${resp.statusText}\n${await resp.text()}`
            );
        const json = await resp.json();
        const containers = json.value.flatMap((v: any) =>
            v.hitsContainers.flatMap((hc: any) => hc.hits)
        );
        return containers.map((c: any) => ({ ...c, index: "jql" }));
    };
}
