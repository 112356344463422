import React, { useRef, useEffect, ReactNode } from "react";

interface FocusTrapProps {
    children: ReactNode;
}

const FocusTrap: React.FC<FocusTrapProps> = ({ children }) => {
    const focusableElementsString =
        'eon-ui-button, a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const trapFocus = (event: KeyboardEvent) => {
            if (!modalRef.current) return;

            const focusableElements =
                modalRef.current.querySelectorAll<HTMLElement>(
                    focusableElementsString
                );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            if (event.key === "Tab") {
                if (event.shiftKey) {
                    // If Shift + Tab, and the focus is on the first element, move it to the last element
                    if (document.activeElement === firstElement) {
                        lastElement.focus();
                        event.preventDefault();
                    }
                } else {
                    // If Tab, and the focus is on the last element, move it to the first element
                    if (document.activeElement === lastElement) {
                        firstElement.focus();
                        event.preventDefault();
                    }
                }
            }
        };

        document.addEventListener("keydown", trapFocus);

        return () => {
            document.removeEventListener("keydown", trapFocus);
        };
    }, []);

    return <div ref={modalRef}>{children}</div>;
};

export default FocusTrap;
