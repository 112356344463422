import {
    AdditionalFilters,
    DataSourceFilter,
    ESApiFinalResponseInterface,
    MetadataFilters,
    QueryType,
} from "../interfaces/ElasticSearchInterface";
import React from "react";
import {mapK} from "../util/kleislis";
import {FilterDateRange, findSourceInfo} from "../domain/info";
import {FindIdFn, interleave, removeDuplicates} from "../util/arrays";
import {TimeService} from "../domain/useTime";
import {SearchContext} from "./searchContext";
import {getFormattedDateTime} from "../util/date";
import {EnvironmentConfig} from "../config/EnvironmentConfig";

export type SearchRequest = {
    searchTerm: string;
    searchIndexes: string[];
    additionalFilters?: AdditionalFilters;
    setDataSourceFiltersWrapper?: (count: DataSourceFilter[]) => void;
    searchAfter?: object;
    expectedRecordSize?: number;
    resultsToDisplay?: any[];
    setMetadataFilters?: (value: MetadataFilters) => void;
};
export type SearchFn<T> = (searchRequest: SearchRequest) => Promise<T[]>;

export function composeSearchFns<T>(
    findIdFn: FindIdFn<T>,
    ...fns: SearchFn<T>[]
): SearchFn<T> {
    const unique = removeDuplicates(findIdFn);
    return async (searchRequest: SearchRequest) => {
        const arrays = await mapK(fns, async (fn) => fn(searchRequest));
        const interwoved = interleave(arrays);
        const result = unique(interwoved);
        return result;
    };
}

export const conditionalSearchFn = <T, >(
    condition: (searchRequest: SearchRequest) => boolean,
    fn: SearchFn<T>
): SearchFn<T> => async (searchRequest) =>
    condition(searchRequest) ? fn(searchRequest) : [];

export const ifThenSearchFn = <T, >(
    condition: (searchRequest: SearchRequest) => boolean,
    thenFn: SearchFn<T>,
    elseFn: SearchFn<T>
): SearchFn<T> => async (searchRequest) => {
    const cond = condition(searchRequest);
    return cond ? thenFn(searchRequest) : elseFn(searchRequest);
};

export const SearchFnContext = React.createContext<SearchFn<any> | undefined>(
    undefined
);

export function useSearchFn(): SearchFn<ESApiFinalResponseInterface> {
    const searchFn = React.useContext(SearchFnContext);
    if (!searchFn)
        throw new Error(
            "useSearchFn must be used within a SearchFnContext.Provider"
        );
    return searchFn;
}

export type SearchFnProviderProps = {
    searchFn: SearchFn<ESApiFinalResponseInterface>;
    children: React.ReactNode;
};

export function SearchFnProvider({
                                     searchFn,
                                     children,
                                 }: SearchFnProviderProps) {
    return (
        <SearchFnContext.Provider value={searchFn}>
            {children}
        </SearchFnContext.Provider>
    );
}

export const dateFormatForEs = (date: Date): string => {
    const yyyy = date.getFullYear();
    const mm = (date.getMonth() + 1).toString().padStart(2, "0"); // Months start at 0!
    const dd = date.getDate().toString().padStart(2, "0");

    return `${yyyy}-${mm}-${dd}`;
};

export type CalcDataRangeFilters = (
    additionalFilters: AdditionalFilters | undefined,
    timeService: TimeService
) => FilterDateRange | undefined;

export const calcDataRangeFilters: CalcDataRangeFilters = (
    additionalFilters: AdditionalFilters | undefined,
    timeService: TimeService
): FilterDateRange | undefined => {
    if (
        !additionalFilters ||
        !additionalFilters.selectedTime ||
        !additionalFilters.selectedTime[0].value
    )
        return undefined;

    const subtractDay = additionalFilters.selectedTime[0].value;
    const subtractDayNum = parseInt(subtractDay, 10);

    if (isNaN(subtractDayNum))
        throw new Error(`Invalid subtractDay value: ${subtractDay}`);

    const today = timeService();
    const dateOffset = 24 * 60 * 60 * 1000 * subtractDayNum;
    const startDate = new Date(today.getTime() - dateOffset);

    return {start: dateFormatForEs(startDate), now: dateFormatForEs(today)};
};

export type ExtractResultFn = (
    extra: Record<string, any>,
    response: any
) => ESApiFinalResponseInterface[];

export type ExtractResultCountFn = (
    response: any
) => any;

export function extractResultThen(
    extract: ExtractResultFn,
    fn: (result: ESApiFinalResponseInterface[]) => ESApiFinalResponseInterface[]
): ExtractResultFn {
    return (extra, response) => fn(extract(extra, response));
}

export function mostRelevant(
    min: number
): (result: ESApiFinalResponseInterface[]) => ESApiFinalResponseInterface[] {
    if (min <= 0 || min > 1)
        throw new Error(
            `Invalid min value: ${min}. Must be in a range of more than 0 up to and including 1`
        );
    return (raw) => {
        const actualMin = raw.length > 0 ? raw[0].score * min : 0;
        return raw.filter((r) => r.score >= actualMin);
    };
}

export const extractResultsFromAxiosResponse: ExtractResultFn = (
    extra: Record<string, any>,
    response: any
): ESApiFinalResponseInterface[] => {
    const hits = response?.data?.hits?.hits;
    if (!Array.isArray(hits)) {
        console.warn(
            "Unexpected response from Elasticsearch. No hits",
            response
        );
        return [];
    }
    return hits.map((hit: any) => ({
        ...extra,
        ...hit._source,
        highlight: hit.highlight,
        index: hit._index,
        id: hit._id,
        score: hit._score,
    }));
};

export const extractResultCountFromAxiosResponse: ExtractResultCountFn = (
    response: any
): any => {
    const aggregations = response?.data?.aggregations?.count?.buckets;
    if (!Array.isArray(aggregations)) {
        console.warn(
            "Unexpected response from Elasticsearch. No aggregations",
            response
        );
        return [];
    }
    return aggregations.map((item: { key: string, doc_count: number }) => ({[item.key]: item.doc_count}));
};

export const extractMetadataFiltersFromAxiosResponse: ExtractResultCountFn = (
    response: any
): any => {
    const filters: any = {};
    const aggregations = response?.data?.aggregations;
    const indicies = EnvironmentConfig.indicies;
    for (const index in aggregations) {
        if (!indicies.includes(index)) continue;
        if (!filters[index]) filters[index] = {};
        for (const indexKey in aggregations[index]) {
            if (indexKey == "doc_count") continue;
            if (!filters[index][indexKey]) filters[index][indexKey] = [];
            const value = aggregations[index][indexKey]?.buckets;
            value?.map((item: { key: string, doc_count: number, label: string }) => {
                item.label = item.key;
                filters[index][indexKey].push(item);
            });
        }
    }
    return filters;
};

export const transformFilterKeyLabels = (
    filters: any
): any => {
    const result: any = {};
    for (const index in filters) {
        if (!result[index]) result[index] = {};
        const source = findSourceInfo(index);
        for (const indexKey in filters[index]) {
            if (source.refineFilterLabels) {
                const {label, value} = source.refineFilterLabels(indexKey, filters[index][indexKey]);
                result[index][indexKey] = {label, value};
            } else {
                result[index][indexKey] = {label: indexKey, value: filters[index][indexKey]};
            }
        }
    }
    return result;
};

export const createDateFilterQuery = (daysAgo: number, searchIndexes: string[]): {
    range: { [key: string]: { gte: string, format: string } }
} | null => {
    if (!searchIndexes.length) return null;
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - daysAgo);
    const source = findSourceInfo(searchIndexes[0]);
    return source.dateFilterKey ? {
        range: {
            [source.dateFilterKey]: {
                gte: getFormattedDateTime(startDate.getTime(), "Y-m-d"),
                format: "yyyy-MM-dd",
            },
        },
    } : null;
};

export const createMetadataFiltersQuery = (additionalFilters: any, searchIndexes: string[]) => {
    const additionalFiltersQuery: any = {filter: []};
    if (!searchIndexes.length) return additionalFiltersQuery;
    const source = findSourceInfo(searchIndexes[0]);
    if (additionalFilters?.selectedTime && source.dateFilterKey) {
        const dateFilter = createDateFilterQuery(additionalFilters?.selectedTime, searchIndexes);
        dateFilter && additionalFiltersQuery.filter.push(dateFilter);
    }
    if (additionalFilters?.selectedMetadata) {
        if (source.createMetadataFilters) {
            additionalFiltersQuery.filter = [...additionalFiltersQuery.filter, ...source.createMetadataFilters(additionalFilters?.selectedMetadata)];
        }
    }
    return additionalFiltersQuery;
};

export type MakeQueryForEsFn = (
    resultSize: number,
    searchTerm: string,
    additionalFilters: any[],
    searchAfter?: object,
) => any;

export const search =
    (
        context: SearchContext,
        queryType: QueryType,
        makeQuery: MakeQueryForEsFn
    ): SearchFn<ESApiFinalResponseInterface> =>
        async (
            searchRequest: SearchRequest
        ): Promise<ESApiFinalResponseInterface[]> => {
            const {
                indicies,
                elasticsearchUrl,
                apikey,
                rawCallElasticSearch,
                extractResults,
                extractMetadataFilters,
            } = context;
            const {
                searchTerm,
                searchIndexes,
                additionalFilters,
                expectedRecordSize,
                searchAfter,
                setMetadataFilters,
                setDataSourceFiltersWrapper
            } = searchRequest;
            const requstedDataSize = additionalFilters ? 200 : 5;
            const resultSize = expectedRecordSize ? expectedRecordSize : requstedDataSize;
            const additionalFiltersQuery: any = setMetadataFilters ? createMetadataFiltersQuery(additionalFilters, searchIndexes) : {filter: []};
            const actualIndicies =
                searchIndexes?.length > 0 ? searchIndexes.join(",") : indicies;
            const response = await rawCallElasticSearch(
                elasticsearchUrl(actualIndicies),
                makeQuery(resultSize, searchTerm, additionalFiltersQuery, searchAfter),
                apikey()
            );
            setDataSourceFiltersWrapper && setDataSourceFiltersWrapper(response?.data?.aggregations?.count?.buckets || []);
            setMetadataFilters && setMetadataFilters(transformFilterKeyLabels(extractMetadataFilters(response)));
            return extractResults[queryType]({queryType}, response);
        };