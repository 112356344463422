import { useTranslation } from "react-i18next";
import "./BookAgain.scss";
import { useEffect, useState } from "react";
import { getAccessToken } from "../../../services/accessToken";
import { convertDaysToMilliseconds, formatDate, getFormattedDateTime } from "../../../util/date";
import { getUserBookingApi } from "../../../services/roomBooking";
import useRoomBookingStore from "../../../store/roomBookingStore";
import Modal from "../../modal/Modal";
import { BookingData, ManageBookingData, MEETING_ROOM_TYPES, PAST_BOOKING_DAYS } from "../RoomBookingInterface";
import SVGIcon from "../../svg-icon/SVGIcon";
import EditBooking from "../edit-booking/EditBooking";
import LoaderComponent from "../../loader-component/LoaderComponent";

type BookAgainProps = {
    callback: () => void;
};

const BookAgain = ({ callback }: BookAgainProps) => {

    const { t } = useTranslation("room-booking");

    const [originalResData, setOriginalResData] = useState<ManageBookingData[]>(
        []
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        rebookingModal,
        setRebookingModal,
        setBookAgainRawData,
        setCurrentBookingData,
    } = useRoomBookingStore();

    useEffect(() => {
        getUserBookings();
    }, []);

    const getUserBookings = async () => {
        setIsLoading(true);
        const actualToken = await getAccessToken();
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const payload = {
            timeframe: {
                begin: {
                    datetime: getFormattedDateTime(
                        currentDate.getTime() -
                        convertDaysToMilliseconds(PAST_BOOKING_DAYS)
                    ),
                },
                end: {
                    datetime: getFormattedDateTime(),
                },
            },
            accesstoken: actualToken || "",
            dummytoken: "",
            include: ["booking_container", "object", "seating"],
        };
        try {
            const response = await getUserBookingApi(payload);
            const bookings = response.data;
            if (response.status == "success") {
                setBookAgainRawData(bookings);
                setOriginalResData(formatManageBookingData(bookings));
            } else {
                console.log("something went wrong!", response);
            }
        } catch (e) {
            console.log("something went wrong!", e);
        } finally {
            setIsLoading(false);
        }
    };

    const formatManageBookingData = (resData: BookingData[]) => {
        const manageBookingData: ManageBookingData[] = [];
        resData.forEach((value: BookingData) => {
            manageBookingData.push({
                meeting_id: value.booking_id,
                meeting_on: formatDate(value.begin.timestamp),
                meeting_timing: `${getFormattedDateTime(
                    value.begin.timestamp * 1000,
                    "H:i",
                    true
                )} - ${getFormattedDateTime(
                    value.end.timestamp * 1000,
                    "H:i",
                    true
                )}`,
                meeting_desc: value.topic,
                meeting_location: value.object_name,
                meeting_type: MEETING_ROOM_TYPES.find(type => type.id === value.object_type_id)?.name ?? "",
                meeting_participants: value.capacity
            });
        });

        return manageBookingData;
    };

    const bookAgain = (data: ManageBookingData) => {
        setCurrentBookingData(data);
        setRebookingModal(true);
    };

    const reloadManageBookingList = () => {
        callback();
    };

    return (
        <div className="child-container" data-testid="bookAgain">
            <div className="page-sub-title">{t("bookAgain")}</div>
            { isLoading && <LoaderComponent isLoading isLinear /> }
            { originalResData.length ? (
                <div className="book-again-container">
                    {originalResData.map((result, key) => (
                        <div className="box" key={key} onClick={() => { bookAgain(result); }} data-testid={`bookAgainModalLink-${key}`}>
                            <div className="item" key={`${key.toString()}`}>
                                <div className="location">{result.meeting_location}</div>
                                <div className="title">{result.meeting_desc}</div>
                                <div className="link">Book</div>
                            </div>
                            <div className="type-icon-wrapper">
                                <SVGIcon
                                    url={`./icons/${
                                        result.meeting_type === "Hot Desk"
                                            ? "one_hotseat"
                                            : "four_room"
                                    }.svg`}
                                />
                            </div>
                        </div>
                    ))}
                    <Modal
                        toggleModal={rebookingModal}
                        closeModal={() => setRebookingModal(!rebookingModal)}
                        isCloseIcon={false}
                        closeOnBackdrop={false}
                        >
                        <EditBooking callback={reloadManageBookingList} action="rebook" />
                    </Modal>
                </div>
            ) : (
                !isLoading && <div className="no-booking-title">{t("noPastBooking")}</div>
            )}

        </div>
    );
};

export default BookAgain;
