import {emptyDataObject, SourceInfo} from "../info";

export const appInfo: SourceInfo = {
    match: "app",
    dropDown: undefined,
    titleAndTarget: (result) => ({
        title: result?.nameToDisplay || result?.title,
        target: result?.target,
        source: undefined,
    }),
    makeDataObject: (result) => ({
        ...emptyDataObject,
        id: result.id,
        index: result.index,
        icon: "app",
        link: result.target,
        name: result.title,
        source: "App",
        ...(result.alias && {alias: result.alias}),
    }),
};

