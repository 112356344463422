import SearchList from "../../search-list";
import {EonUiButton} from "@eon-ui/eon-ui-components-react";
import {useContext, useState} from "react";
import {AiAssistContext} from "../AiAssist.context";
import {useTranslation} from "react-i18next";

const AiFooter = () => {
    const {t} = useTranslation();
    const {handleAiQuery, currentCardKey, cards} = useContext(AiAssistContext);
    const [inputVal, setInputVal] = useState("");
    return (
        <>
            <div className="ai-assist-footer">
                <div className="input-wrapper">
                    <div style={{width: "100%"}}>
                        <SearchList
                            itemClickedCallback={(item) => handleAiQuery(item)}
                            queryChange={(value) => setInputVal(value)}
                            isChatBotSearch
                            limitedHeight
                            listReverse
                            defaultInputVal={inputVal}
                            disabledSearch={cards[currentCardKey]?.disableSearch}
                            indicies={cards[currentCardKey]?.indicies}
                        />
                    </div>
                    <EonUiButton
                        title={t("chatBot.sendbtn")}
                        placeholder={""}
                        disabled={inputVal.trim().length < 1}
                        onClick={() => {
                            handleAiQuery(inputVal);
                            setInputVal("");
                        }}
                        className="margin-top-space"
                        icon="send"
                    ></EonUiButton>
                </div>
            </div>
            <div className="disclaimer-text">{t("chatBot.disclaimerText")}</div>
        </>
    );
};

export default AiFooter;