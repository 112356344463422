import ragComponent from "./rag.component";
import showIncidentsOrOrders from "./showIncidentsOrOrders.component";
import { IActionComponentProps } from "./types";

const lookupTableAboutChatbotActions: IActionComponentProps = {
    rag: ragComponent,
    "show-incidents": showIncidentsOrOrders,
    "show-orders": showIncidentsOrOrders,
    default: ragComponent,
};

export { lookupTableAboutChatbotActions };
