import {
    TGenerateAiResponseFn,
    TGenerateAiResponseProps
} from "../ai.prompts";
import {getFrom, MapOrDefault} from "../../../util/map";

type AzureBlobSearchResult = {
    title: string;
    answer: string;
    keyfield: string;
    summary: string;
    chunk_type: "q_and_a" | "original_document"
    t: {
        answer: {
            [key: string]: string;
        };
        question: {
            [key: string]: string;
        };
    };
    url: string;
}

export const auzreBlobRagPromptTemplates: MapOrDefault<TGenerateAiResponseFn<AzureBlobSearchResult>> = {
    "q_and_a": ({searchResult, language}) => ` 
            ## Matched Q&A:
            **Question**: ${searchResult.keyfield}
            **Answer**: ${searchResult.answer}
            
            ## Matched Document Summary:
            **SUMMARY**:
            ${searchResult.summary}
            **END OF SUMMARY**
            
            **CONTEXT**: You're company employee support agent. User asked a question that 
            went to the ElasticSearch and found matched document with general answer. 
            
            **INSTRUCTIONS**: 
            - See Matched Q&A. There is the Answer to the user's question.
            - **IMPORTANT!** Always if user question includes some personal conditions (for example: 
                Where is *my laptop*? Where is *my office*?) respond with general answer. 
            - If Matched Q&A includes URL, keep URL in the response.
            - Ensure the response is in natural language, accurate and concise.
            
            Last step- Respond in language: ${language} ${searchResult.url ? `and include the link to the azureblob document: ${searchResult.url}` : ""}`,

    "original_document": ({searchResult, language}) => ` Matched Document:
            ${searchResult.keyfield}
            
            CONTEXT: You're company employee support agent. User asked a question that 
            went to the ElasticSearch and found matched document with general answer. 
            
            INSTRUCTIONS: 
            1) See Matched Document. There is the content of the document.
            2) If Matched Document includes URL, keep URL in the response.
            3) Ensure the response is in natural language, accurate and concise.
            
            Last step- Respond in language: ${language} ${searchResult.url ? `and include the link to the azureblob document: ${searchResult.url}` : ""}`,
    default: ({language}) => `Respond that we don't have an answer for this question in ${language}.`
};

export const createAzureBlobRagPrompt =
    ({
         query,
         searchResult,
         language,
     }: TGenerateAiResponseProps<AzureBlobSearchResult>) => getFrom(auzreBlobRagPromptTemplates)(searchResult.chunk_type)({
        query,
        searchResult,
        language
    });