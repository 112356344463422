import { languages } from "../language-switch/LangInterface";
import { useTranslation } from "react-i18next";
import {
    EonUiRadioButtonGroup,
    EonUiRadioButton,
    EonUiButton,
} from "@eon-ui/eon-ui-components-react";
import useSearchStore from "../../store/searchStore";

const TourLangOptions = ({ tourAction }: any) => {
    const { setPrefData } = useSearchStore();
    const { t, i18n } = useTranslation();

    const handleChangeLocale = (code: string) => {
        i18n.changeLanguage(code);
        localStorage.setItem("i18nextLng", code);
        setPrefData(code);
    };

    return (
        <div className="tour-lang-options" style={{ maxWidth: "400px" }}>
            <h4>{t("tourData.userActions.tour_info")}</h4>
            <EonUiRadioButtonGroup
                scheme="neutral800"
                name="my-group"
                onValueChanged={(e) => handleChangeLocale(e.target.value)}
            >
                {languages.map((language) => {
                    const isSelected = language.code === i18n.language;
                    return (
                        <EonUiRadioButton
                            key={language.code}
                            initiallyChecked={isSelected}
                            value={language.code}
                            label={language.name}
                        />
                    );
                })}
            </EonUiRadioButtonGroup>
            <div className="common-button-wrapper">
                <EonUiButton
                    fullWidthOnMobile
                    text={t("continue_Tour")}
                    rank="primary"
                    size="small"
                    onClick={() => tourAction("start")}
                />
                <EonUiButton
                    fullWidthOnMobile
                    text={t("skip_Tour")}
                    rank="secondary"
                    size="small"
                    onClick={() => tourAction("skip")}
                />
            </div>
        </div>
    );
};

export default TourLangOptions;
