import { EonUiLink } from "@eon-ui/eon-ui-components-react";
import "./QuickActionLinks.scss";
import { useTranslation } from "react-i18next";

interface QuickActionLinksProps {
    actionItems: { type: string, icon: string }[];
    show: boolean;
    onItemClick?: (item: string) => void;
}

const QuickActionLinks = ({
    actionItems: items,
    show,
    onItemClick,
}: QuickActionLinksProps) => {

    const { t } = useTranslation();

    const handleClick = (item: string) => {
        console.log("Clicked item:", item);
        if (onItemClick) {
            onItemClick(item);
        }
    };

    return (
        <div className="quick-links-wrapper" style={{ display: show ? 'flex' : 'none' }}>
            {items.map((item, index) => (
                <EonUiLink
                    key={index}
                    className="item"
                    onClick={() => handleClick(item.type)}
                    icon={item.icon}
                    iconPosition="left"
                    text={t(`chatBot.quickLinks.${item.type}`)}
                    size="small"
                ></EonUiLink>
            ))}
        </div>
    );
};

export default QuickActionLinks;
