import {adminCellStyles, adminTableStyles} from "./adminTableStyles";
import {useAuth} from "../../MsalContext";
import  {elasticSearchWebUrl,EnvironmentConfig} from "../../config/EnvironmentConfig";

export function AdminLinks() {
    function OneLink({name, url}: { name: string, url: string }) {
        return <tr key={name}>
            <td style={adminCellStyles}>{name}</td>
            <td style={adminCellStyles}><a href={url}>{url}</a></td>
        </tr>;
    }

    const {account} = useAuth();
    console.log(account);
    const email = account?.username;
    return <div>
        <table style={adminTableStyles}>
            <tbody>
            <OneLink name='Elastic Search' url={elasticSearchWebUrl()}/>
            <OneLink name='Elastic Search indicies' url={`${elasticSearchWebUrl()}app/enterprise_search/content/search_indices`}/>
            <OneLink name='Elastic Search dashboards' url={`${elasticSearchWebUrl()}app/dashboards`}/>
            <OneLink name='ApiKey debug' url={`${EnvironmentConfig.elasticSearchDashboardUrl}/${email}?debug=true`}/>
            <OneLink name='Node Indexer config' url='https://git.eon-cds.de/repos/me8eonmvp1/node-indexer/-/blob/main/indexer.yaml'/>
            <OneLink name='Node Indexer pipelines' url='https://git.eon-cds.de/repos/me8eonmvp1/node-indexer/-/pipelines'/>
            <OneLink name='me@eon gui pipeline' url='https://git.eon-cds.de/repos/me8eonmvp1/me8eon-sap-connect-poc/-/pipelines'/>
            </tbody>
        </table>
    </div>;
}