import React from "react";
import { EonUiButton } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import { TabDataInterface } from "../../interfaces/TileInterface";

interface DeleteTabProps {
    isDeleteConfirmed: (isDeleteConfirm: boolean) => void;
    deleteTabObj: TabDataInterface | null;
}

const TabDeleteConfirm: React.FC<DeleteTabProps> = ({
    isDeleteConfirmed,
    deleteTabObj,
}) => {
    const { t } = useTranslation();

    return (
        <div className="delete-tab-confirm">
            <div>
                {t("moveTabAndTiles.removeTabConfrim")}&nbsp;
                <b>&quot;{deleteTabObj?.name}&quot;</b>
                {t("moveTabAndTiles.with")}&nbsp;
                <b>({deleteTabObj?.count})&nbsp;</b>
                {t("moveTabAndTiles.cardIn")}
            </div>
            <div className="delete-tab-message">
                {t("moveTabAndTiles.actionUndone")}
            </div>
            <div className="common-button-wrapper">
                <EonUiButton
                    fullWidthOnMobile={true}
                    text={t("delete")}
                    rank="primary"
                    size="small"
                    onClick={() => {
                        isDeleteConfirmed(true);
                    }}
                ></EonUiButton>
                <EonUiButton
                    fullWidthOnMobile={true}
                    text={t("cancel")}
                    rank="secondary"
                    size="small"
                    onClick={() => {
                        isDeleteConfirmed(false);
                    }}
                ></EonUiButton>
            </div>
        </div>
    );
};
export default TabDeleteConfirm;
