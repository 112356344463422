export type MapOrDefault<T> = Record<string, T>;

export const getFrom = <T>(m: MapOrDefault<T>) => {
    if (!m["default"])
        throw new Error(
            "No default key in map. Keys are " + Object.keys(m).sort()
        );
    return (key: string | undefined): T =>
        key === undefined ? m["undefined"] : m[key] || m["default"];
};
