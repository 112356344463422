import { emptyDataObject, SourceInfo } from "../info";
import { uppercaseFirstLetterOnly } from "../../util/strings";
import { dateFormat } from "../../util/date";

export const userInfo: SourceInfo = {
    match: "user",
    dropDown: undefined,
    titleAndTarget: () => {
        throw new Error("Not implemented");
    },
    makeDataObject: (result) => ({
        ...emptyDataObject,
        id: result.id,
        index: result.index,
        icon: "haiilo",
        link: `https://dev-connect.eon.com/profile/${result?.target?.params?.slug}`,
        name: result.displayName,
        source: "Connect - User",
        status: uppercaseFirstLetterOnly(result.status),
        desc: result.email,
        createdAt: dateFormat(result.createdDate),
    }),
};
