import React from 'react';
import {adminCellStyles, adminTableStyles, rightAlignAdminCellStyles} from "./adminTableStyles";

export type AdminTableProps<T> = {
    data: T[];
    rightJustified?: string[];
    renderCell: Record<string, (item: T,rowIndex:number) => React.ReactNode>;
};

export function AdminTable<T>({data, renderCell, rightJustified = []}: AdminTableProps<T>) {
    function style(title: string) {
        const style = rightJustified?.includes(title) ? rightAlignAdminCellStyles : adminCellStyles;
        return style;
    }

    return <table style={adminTableStyles}>
        <thead>
        <tr>{Object.keys(renderCell).map((title, index) =>
            <th key={index} style={style(title)}>{title}</th>)
        }</tr>
        </thead>
        <tbody>
        {data.map((item, rowIndex) => <tr key={rowIndex}>{
            Object.entries(renderCell).map(([title, render]) =>
                <td key={title} style={style(title)}>{render(item,rowIndex)}</td>)}
        </tr>)}
        </tbody>
    </table>;
}
