import React from "react";
import {DataType} from "./ElSearchAdvanceHelpers";
import {SelectFilterButton} from "./SelectFilterButton";
import {FilterSetters} from "./elsearch.domain";
import {useJqlFlag} from "../../hooks/useJqlFlag";
import {useTranslation} from "react-i18next";

interface SearchFiltersInterface {
    setters: FilterSetters;
    dataType: DataType;
}

const SearchFiltersTop: React.FC<SearchFiltersInterface> = ({
    setters,
    dataType,
}) => {
    const { t } = useTranslation();
    return (
        <div className="filter-conatiner">
            <div className="looking-for-text">{t("filterPage.filterMenu.lookingFor")}</div>
            <div className="filter-tabs filter-tabs-top">
                <SelectFilterButton
                    setters={setters}
                    textkey="filterPage.filterMenu.all"
                    id="default"
                    clazz={dataType === "all" ? "active" : ""}
                    dataType="all"
                    value={[]}
                />
                <SelectFilterButton
                    setters={setters}
                    textkey="filterPage.filterMenu.app"
                    clazz={dataType === "apps" ? "active" : ""}
                    dataType="apps"
                    value={"app"}
                />
                <SelectFilterButton
                    setters={setters}
                    textkey="filterPage.filterMenu.m365"
                    clazz={dataType === "m365" ? "active" : ""}
                    dataType="m365"
                    value={"m365"}
                />
                {useJqlFlag() && (
                    <SelectFilterButton
                        setters={setters}
                        textkey="filterPage.filterMenu.jql"
                        clazz={dataType === "jql" ? "active" : ""}
                        dataType="jql"
                        value={"jql"}
                    />
                )}
                <SelectFilterButton
                    setters={setters}
                    textkey="Assistance"
                    clazz={dataType === "assistance" ? "active" : ""}
                    dataType="assistance"
                    value={[]}
                />
            </div>
        </div>
    );
};
export default SearchFiltersTop;
