import {EnvironmentConfig} from "../../../config/EnvironmentConfig";

const parseLinks = (str: string) => {
    let linkCount = 0;
    // Replace markdown-style links with HTML <a> tags
    const withHtmlLinks = str.replace(
        /\[(.*?)\]\((.*?)\)/g,
        (match, p1, p2) => {
            linkCount++; // Increment the counter for each link
            const urlText = p1.startsWith("https:")
                ? `link ${linkCount}`
                : `${linkCount}-${p1}`;
            if (p2.startsWith(EnvironmentConfig.azureBlobUrl as string)) {
                p2 = `${p2}?${process.env.REACT_APP_AZURE_BLOB_ACCESS_KEY}`;
            }
            return `<a target="_blank" href="${p2}" data-original-text="${urlText}">(${linkCount}) </a>`;
        }
    );

    const withCustomLinks = withHtmlLinks.replace(
        /\[\[(https?:\/\/[^\s]+?)\]\]/g, // Match URLs inside [[ ]]
        (match, p1) => {
            linkCount++;
            return generateLink(p1, linkCount);
        }
    );

    // Replace bare URLs with HTML <a> tags
    // This regex will not try to exclude URLs already inside an href attribute,
    // but it's more widely supported.
    return withCustomLinks.replace(/(https?:\/\/[^\s]+)/g, (match, p1) => {
        // To avoid double-wrapping URLs already in <a> tags,
        // we can check if they are already wrapped in <a>
        if (
            /href="/.test(
                withCustomLinks.slice(
                    withCustomLinks.indexOf(match) - 10,
                    withCustomLinks.indexOf(match) + 10
                )
            )
        ) {
            return match; // Return the match as-is if already within an href attribute
        }
        linkCount++;
        return generateLink(p1, linkCount);
    });
};

function generateLink(p1: string, linkCount: number): string {
    const urlWithoutParams: string = p1.split(/[?#]/)[0];

    if (isPdfLink(urlWithoutParams)) {
        const pdfName = extractPdfName(urlWithoutParams);
        if (
            urlWithoutParams.startsWith(
                EnvironmentConfig.genAiApiBaseUrl as string
            )
        ) {
            return `<a target="_blank" href="${p1}" style="display:none" data-original-text="${linkCount}-${pdfName}">(${linkCount}) </a>`;
        }
        return `<a target="_blank" href="${p1}" data-original-text="${linkCount}-${pdfName}">(${linkCount}) </a>`;
    }
    return `<a target="_blank" href="${p1}" data-original-text="link ${linkCount}">(${linkCount}) </a>`;
}

function isPdfLink(url: string): boolean {
    return url.endsWith(".pdf");
}

function extractPdfName(url: string): string {
    const pdfName = url.split("/").pop()?.split("__").pop();
    return decodeURIComponent(pdfName || "pdf doc");
}

export default parseLinks;

export const parsedLinkArray = (text: string) => {
    const regex = /href="(.*?)"(?:.*?data-original-text="(.*?)")?/g;
    let matches;
    const links = [];
    while ((matches = regex.exec(text)) !== null) {
        const linkObj = {
            url: matches[1],
            text: matches[2] || null,
        };
        links.push(linkObj);
    }
    return links;
};