import React, {useEffect, useState} from "react";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./ElSearchAdvance.scss";
import {EonUiIcon, EonUiLink} from "@eon-ui/eon-ui-components-react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ElSearchAdvanceCard from "./results/ElSearchAdvanceCard";
import {getDataToDisplayFrom, search} from "./ElSearchGetData";
import SearchFiltersTop from "./SearchFiltersTop";
import {DataType, SearchInput} from "./ElSearchAdvanceHelpers";
import {FilterSetters} from "./elsearch.domain";
import LoaderComponent from "../loader-component/LoaderComponent";
import {useSearchFn} from "../../search/search";
import {FeedbackFn, useFeedback} from "../feedback/feedback";
import {useAuth} from "../../MsalContext";
import SearchFiltersRight from "./SearchFiltersRight";
import {
    DataSourceFilter,
    MetadataFilters,
    SelectedMetadataFilters,
} from "../../interfaces/ElasticSearchInterface";
import AiAssist from "../ai-assist-refactored/AiAssist";
import {AiAssistProvider} from "../ai-assist-refactored/AiAssist.context";
import AiButton from "../ai-button/AiButton";
import UnifiedSearch from "./unified-search/UnifiedSearch";
import {ExplainControlCenter} from "../diagnostics/explain";
import {useExplainFlag} from "../../hooks/useExplain";
import {useFilterDebugFlag} from "../../hooks/useFilterDebugFlag";
import {FilterDebug} from "./filter-debug/filter.debug";
import {isM365} from "./TabFilterStrategy";

function NoData({noMoreData, searchTerm}: { noMoreData?: boolean, searchTerm?: string; }) {
    const {t} = useTranslation();
    return (
        <li className={noMoreData ? "no-more-data" : "no-data"} key={"noData"}>
            {noMoreData ? <></> : <EonUiIcon
                scheme="red500" 
                placeholder="search"
                size="normal"
                key={"search"}
                name="search"
            ></EonUiIcon>}
            {t(`filterPage.filterMenu.${noMoreData ? "noMoreData" : "noData"}`)}
            { noMoreData ? '' : <>&nbsp;<strong>{searchTerm}</strong></> }
        </li>
    );
}

const ElSearchAdvance: React.FC = () => {
    const [dataType, setDataType] = useState<DataType>("all");
    const [searchIndex, setSearchIndex] = useState<string[]>([]);
    const [additionalFilters, setAdditionalFilters] = useState<any>({});
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [resultCount, setResultCount] = useState<number>(0);
    const [dataSourceFilters, setDataSourceFilters] = useState<
        DataSourceFilter[]
    >([]);
    const [resultsToDisplay, setResultsToDisplay] = useState<any[]>([]);
    const [filterCount, setFilterCount] = useState<number>(0);
    const [showAiAssist, setShowAiAssist] = useState(false);
    const [showRecords, setShowRecords] = useState<any[]>([]);
    const [scrollCompleted, setScrollCompleted] = useState<boolean>(false);
    const [joinData, setJoinData] = useState<boolean>(false);
    // const [relatedApps, setRelatedApps] = useState<any[]>([]);
    const [lastRecord, setLastRecord] = useState<{
        score: number;
        id: string;
    } | null>(null);
    const [metadataFilters, setMetadataFilters] = useState<MetadataFilters>({});
    const [selectedMetadataFilters, setSelectedMetadataFilters] =
        useState<SelectedMetadataFilters>({});
    const {t} = useTranslation();
    const {isLimitedAccess} = useAuth();

    function enterPageAndSetSearchFromUrl() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const searchQuery = urlParams.get("q");
        if (searchQuery) setSearchTerm(searchQuery);
        //     if (!isQaPocUser)
        //         (document.getElementById("default") as HTMLElement).click();
    }

    useEffect(() => {
        enterPageAndSetSearchFromUrl();
    }, []);

    const setters: FilterSetters = {
        setIsLoading,
        setResultsToDisplay,
        setResultCount,
        setDataSourceFilters: setDataSourceFiltersWrapper,
        setDataType,
        setSearchIndex,
        setAdditionalFilters,
        setMetadataFilters,
        setScrollCompleted,
        setFilterCount,
    };

    const searchFn = useSearchFn();
    const feedbackFn: FeedbackFn = useFeedback();

    useEffect(() => {
        const handleScroll = () => {
            const scrolled =
                window.innerHeight + window.scrollY + 300 >=
                document.body.offsetHeight;
            !scrollCompleted && scrolled && setScrollCompleted(scrolled);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        const joinDataLocal = joinData;
        setJoinData(false);
        const oldRecords = joinDataLocal ? showRecords : [];
        const jointList = [...oldRecords, ...resultsToDisplay];
        setShowRecords(scrollCompleted ? jointList : resultsToDisplay);
        setScrollCompleted(false);
        setLastRecord(resultsToDisplay[resultsToDisplay.length - 1]);
        udpateSelectedMetadataFilters();
    }, [resultsToDisplay]);

    useEffect(() => {
        setMetadataFilters({});
    }, [searchTerm, searchIndex]); //a hack until we have a better way to handle this bug
    const executeSearch = search(
        searchTerm,
        () => {
            feedbackFn({
                source: "query",
                page: "advanced",
                query: searchTerm,
            });
            return searchFn({
                searchTerm,
                searchIndexes: searchIndex,
                additionalFilters,
                setDataSourceFiltersWrapper,
                ...(scrollCompleted &&
                    lastRecord && {
                        searchAfter: [lastRecord.score, lastRecord.id],
                    }),
                expectedRecordSize: 20,
                setMetadataFilters: setMetadataFiltersWrapper,
            });
        },
        getDataToDisplayFrom(),
        setters
    );

    useEffect(() => {
        if (!scrollCompleted || resultsToDisplay.length === 0) {
            return;
        }
        const delayDebounceFn = setTimeout(() => {
            executeSearch();
            udpateSelectedMetadataFilters();
            setJoinData(true);
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [scrollCompleted]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            executeSearch();
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [searchTerm, searchIndex, additionalFilters]);

    useEffect(() => {
        if (dataType === "assistance") {
            setShowAiAssist(true);
        }
    }, [dataType]);

    useEffect(() => {
        setAdditionalFilters({});
    }, [searchTerm]);

    function udpateSelectedMetadataFilters() {
        if (searchIndex.length) {
            setSelectedMetadataFilters(metadataFilters[searchIndex[0]]);
        }
    }

    function setDataSourceFiltersWrapper(count: DataSourceFilter[]) {
        if (searchIndex.length) {
            searchIndex.forEach((index) => {
                let found = false;
                const newCount =
                    count.find((c) => c.key === index)?.doc_count ?? 0;
                dataSourceFilters.forEach((ds) => {
                    if (index === ds.key) {
                        ds.doc_count = newCount;
                        found = true;
                    }
                });
                if (!found) {
                    dataSourceFilters.push({key: index, doc_count: newCount});
                }
            });
            setDataSourceFilters([...dataSourceFilters]);
        } else {
            setDataSourceFilters(count);
        }
    }

    function setMetadataFiltersWrapper(filters: MetadataFilters) {
        try {
            for (const key in additionalFilters.selectedMetadata) {
                if (!additionalFilters.selectedMetadata[key].length) continue;
                filters[searchIndex[0]][key] = {
                    ...metadataFilters[searchIndex[0]][key],
                };
            }
        } catch (e) {
            console.error(e, filters, metadataFilters);
        }
        setMetadataFilters(filters);
    }

    return showAiAssist ? (
        <AiAssistProvider>
            <AiAssist
                setShowAiAssist={setShowAiAssist}
                initialPrompt={searchTerm}
            />
        </AiAssistProvider>
    ) : (
        <div className="search-custom-filter-wrapper">
            <Link to="/tile">
                <EonUiLink
                    className="back-button"
                    icon="chevron_small_left"
                    iconPosition="left"
                    placeholder="back"
                    href="javascript:void(0)"
                    text={t("filterPage.filterMenu.backBtn")}
                    title={t("filterPage.filterMenu.backBtn")}
                ></EonUiLink>
            </Link>
            {useExplainFlag() && <ExplainControlCenter query={searchTerm} index={searchIndex[0]}/>}
            {useFilterDebugFlag() && <FilterDebug setters={setters}
                                                  searchIndex={searchIndex}
                                                  additionalFilters={additionalFilters}
                                                  metadataFilters={selectedMetadataFilters}
                                                  dataSourceFilters={dataSourceFilters}
                                                  filterCount={filterCount}/>}
            <div className="search-input">
                <SearchInput
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
                {!isLimitedAccess && (
                    <AiButton
                        title={t("chatBot.chatBotTitle")}
                        aiToggle={() => setShowAiAssist(true)}
                    />
                )}
            </div>
            <LoaderComponent isLoading={isLoading} isLinear/>
            <SearchFiltersTop setters={setters} dataType={dataType}/>
            {((dataType === "all" && filterCount < 1) ||
                dataType === "assistance") && (
                <UnifiedSearch
                    searchTerm={searchTerm}
                    setters={setters}
                    setShowAiAssist={setShowAiAssist}
                />
            )}
            <div className="custom-result">
                {dataType !== "apps" && searchTerm && !isM365(searchIndex) && (
                    <SearchFiltersRight
                        setters={setters}
                        searchIndex={searchIndex}
                        additionalFilters={additionalFilters}
                        metadataFilters={selectedMetadataFilters}
                        dataSourceFilters={dataSourceFilters}
                        filterCount={filterCount}
                    />
                )}
                {dataType !== "assistance" && searchTerm && (
                    <ul className="custom-result-container">
                        {showRecords?.map((result, key) => (
                            <ElSearchAdvanceCard
                                result={result}
                                key={`${key.toString()}`}
                                searchTerm={searchTerm}
                                position={key + 1}
                            />
                        ))}
                        {!isLoading && resultCount === 0 && (
                            <NoData noMoreData={showRecords.length > 0} searchTerm={searchTerm} />
                        )}
                    </ul>
                )}
            </div>
            {scrollCompleted && (
                <LoaderComponent isLoading={isLoading} isLinear/>
            )}
        </div>
    );
};
export default ElSearchAdvance;
