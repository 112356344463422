import { EonUiIcon, EonUiLink } from "@eon-ui/eon-ui-components-react";
import React, { useEffect } from "react";
import "./RelatedApps.scss";
import useSearchStore from "../../../../store/searchStore";
import { FilterSetters } from "../../elsearch.domain";
import { EnvironmentConfig } from "../../../../config/EnvironmentConfig";
import { useTranslation } from "react-i18next";

// Main RelatedApps component
interface RelatedAppsProps {
    // relatedApps: any[];
    setters: FilterSetters;
    searchTerm: string;
}

const RelatedApps: React.FC<RelatedAppsProps> = ({ searchTerm, setters }) => {
    const {
        data: displayApps,
        setPrefData,
        toggleFavourite,
        fetchSearchData,
    } = useSearchStore();
    const {
        setIsLoading,
        setResultsToDisplay,
        setResultCount,
        setDataType,
        setSearchIndex,
        setScrollCompleted,
    } = setters;

    const { t } = useTranslation();

    useEffect(() => {
        fetchSearchData(searchTerm);
    }, [searchTerm]);

    const onAddFavorite = (appData: any) => {
        console.log("Add button clicked", appData);
        toggleFavourite(appData);
        setPrefData();
    };
    // console.log("relatedApps", displayApps);

    const appsAsActive = () => {
        const indices = [EnvironmentConfig.appIndex.toString()];
        setIsLoading(true);
        setScrollCompleted(false);
        setResultsToDisplay([]);
        setSearchIndex([]);
        setResultCount(0);
        setDataType("apps");
        setSearchIndex(indices);
    };

    if (displayApps.length === 0) {
        return null;
    }

    return (
        <div className="related-apps-container">
            <div className="related-apps-title">
                {t("searchPage.relatedApps")}
            </div>
            <ul className="related-apps-ul">
                {displayApps.map((appData, index) => {
                    const { target, title, alias } = appData;

                    const translatedLabel = alias
                        ? t(`appData.${alias}`)
                        : title;
                    const label = translatedLabel.startsWith("appData.")
                        ? title
                        : translatedLabel;

                    return (
                        <li key={index} className="related-apps-li">
                            <a
                                href={target ? target : `javascript:void(0)`}
                                target={target ? "_blank" : "_self"}
                                rel="noreferrer"
                                className="related-apps-link"
                                // onClick={() => itemClicked(queTitle)}
                            >
                                {label && (
                                    <div
                                        className="related-apps-label"
                                        dangerouslySetInnerHTML={{
                                            __html: label,
                                        }}
                                    ></div>
                                )}
                            </a>
                            <button
                                onClick={() => onAddFavorite(appData)}
                                className="add-icon"
                                title={
                                    appData.status
                                        ? t("searchPage.removeAppTooltip")
                                        : t("searchPage.addAppTooltip")
                                }
                            >
                                <EonUiIcon
                                    placeholder={""}
                                    name={appData.status ? "subtract" : "add"}
                                    className="add-remove-icon"
                                    scheme="red500"
                                    size="normal"
                                    title={appData.status ? t("searchPage.removeAppTooltip") : t("searchPage.addAppTooltip")}
                                ></EonUiIcon>
                            </button>
                        </li>
                    );
                })}
            </ul>
            {displayApps.length >= 3 && (
                <div className="view-more-apps">
                    <EonUiLink
                        data-testid="about-me8eon"
                        size="small"
                        slot="links"
                        target="_blank"
                        text={t("searchPage.viewMoreApps")}
                        // hideIcon={true}
                        onClick={appsAsActive}
                    ></EonUiLink>
                </div>
            )}
        </div>
    );
};
export default RelatedApps;
