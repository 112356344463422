import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const msalConfig: Configuration = {
    auth: {
        clientId:
            process.env.REACT_APP_K8S_MSAL_CLIENT_ID ??
            "ec963ff8-b8c7-411e-80b1-9473d0390b3b", ///replace with ur azure ad application id
        authority: `https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a`, ///replace with ur azure ad directory (tenent id)
        redirectUri: "/tile",
        postLogoutRedirectUri: "/",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
};

export const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);
