export const getBlobUrl = async (pdfUrl: string, authToken: string) => {
    const response = await fetch(pdfUrl, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    if (!response.ok) {
        throw new Error("Network Error");
    }

    // Convert the response to a Blob
    const blob = await response.blob();
    // Create a Blob URL
    const blobUrl = window.URL.createObjectURL(blob);
    return blobUrl;
};
export const openPdfInNewTab = async (
    pdfUrl: string,
    authToken: string
): Promise<void> => {
    try {
        const blobUrl = await getBlobUrl(pdfUrl, authToken);
        // Open the Blob URL in a new tab
        const newTab = window.open(blobUrl, "_blank");

        if (newTab) {
            // Optional: Revoke the Blob URL after the new tab has loaded the PDF
            newTab.onload = () => {
                window.URL.revokeObjectURL(blobUrl);
            };
        } else {
            alert("Please allow popups for this website");
        }
    } catch (error) {
        console.error("Error fetching the PDF:", error);
        alert("Failed to open PDF.");
    }
};
