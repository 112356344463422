import "./ElSearchAdvanceCard.scss";
import { DataObject, findDataObject } from "../../../domain/info";
import { Description } from "./Description";
import { Feedback } from "../../feedback/feedback.component";
import {
    FeedbackPayload,
    LikeOrDislike,
    useFeedback,
} from "../../feedback/feedback";
import React from "react";
import { useTranslation } from "react-i18next";
import { EonUiLink } from "@eon-ui/eon-ui-components-react";
import {useExplainFlag} from "../../../hooks/useExplain";
import {Explain} from "../../diagnostics/explain";

export type LinkWithFeedbackProps = {
    query: string;
    className: string;
    href: string;
    target: string;
    rel: string;
    position?: number;
    index: string;
    id: string;
    children: React.ReactNode;
};

export function LinkWithFeedback({
    index,
    id,
    className,
    href,
    target,
    rel,
    position,
    children,
}: Readonly<LinkWithFeedbackProps>) {
    const feedbackFn = useFeedback();
    const onClick = () => {
        feedbackFn({ source: "click", href, index, id, position });
    };
    return (
        <a
            className={className}
            href={href}
            target={target}
            rel={rel}
            onClick={onClick}
        >
            {children}
        </a>
    );
}

export type DataObjAsProps = { dataObj: DataObject; searchTerm?: string; position?: number; };
export function IconAndLink({ dataObj, searchTerm, position }: Readonly<DataObjAsProps>) {
    const { id, index, alias } = dataObj;
    const { t } = useTranslation();
    let appTranslatedLabel = alias
        ? t(`appData.${dataObj.alias}`)
        : dataObj.name;
    if (appTranslatedLabel===undefined) {
        console.log('appTranslatedlable is undefined');
    }
    if (appTranslatedLabel?.startsWith("appData.")) {
        appTranslatedLabel = dataObj.name;
    }

    return (
        <div className="row-1">
            {dataObj.icon && (
                <img
                    loading="lazy"
                    className="search-item-logo"
                    src={`./icons/el-search-images/${dataObj.icon}.svg`}
                    alt={dataObj.icon}
                />
            )}
            <div className="links">
                <LinkWithFeedback
                    className="search-item-link"
                    href={dataObj.link}
                    target="_blank"
                    rel="noreferrer"
                    position={position}
                    query={searchTerm ?? ""}
                    index={index}
                    id={id}
                >
                    {appTranslatedLabel}
                </LinkWithFeedback>
            </div>
        </div>
    );
}

export function StatusAndCreated({ dataObj }: Readonly<DataObjAsProps>) {
    const { t } = useTranslation();
    const {
        lastUpdate,
        createdAt,
        status,
        type,
        source,
        language,
        duration,
        createdBy,
        deliveryTime,
        category
    } = dataObj;
    return (
        <div className="row-2">
            {source && (
                <span>
                    {t("filterPage.searchResult.source")}:{" "}
                    <span className="value">{source}</span>
                </span>
            )}
            {type && (
                <span>
                    {t("filterPage.searchResult.type")}:{" "}
                    <span className="value">{type}</span>
                </span>
            )}
            {duration && (
                <span>
                    {t("filterPage.searchResult.duration")}:{" "}
                    <span className="value">{duration}</span>
                </span>
            )}
            {deliveryTime && (
                <span>
                    {t("filterPage.searchResult.deliveryTime")}:{" "}
                    <span className="value">{deliveryTime}</span>
                </span>
            )}
            {category && (
                <span>
                    {t("filterPage.searchResult.category")}:{" "}
                    <span className="value">{category}</span>
                </span>
            )}
            {language && (
                <span>
                    {t("filterPage.searchResult.availableLanguages")}:{" "}
                    <span className="value">{language}</span>
                </span>
            )}
            {status && (
                <span>
                    {t("filterPage.searchResult.status")}:{" "}
                    <span className="value">{status}</span>
                </span>
            )}
            {createdBy && (
                <span>
                    {t("filterPage.searchResult.createdBy")}:{" "}
                    <span className="value">{createdBy}</span>
                </span>
            )}
            {lastUpdate && (
                <span>
                    {t("filterPage.searchResult.lastUpdate")}:{" "}
                    <span className="value">{lastUpdate}</span>
                </span>
            )}
            {createdAt && (
                <span>
                    {t("filterPage.searchResult.createdOn")}:{" "}
                    <span className="value">{createdAt}</span>
                </span>
            )}
        </div>
    );
}

export function RenderElSearchAdvanceCard({
    dataObj,
    searchTerm,
    position,
}: Readonly<DataObjAsProps>) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isPreviewTest = urlParams.get("preview_test");

    function dataToSend(likeDislike: LikeOrDislike | undefined) {
        const payload: FeedbackPayload = {
            query: searchTerm ?? "",
            like: likeDislike,
            source: "search",
            id: dataObj.id,
            index: dataObj.index,
        };
        return payload;
    }

    const showPreview = (url: string) => {
        window.open(
            url,
            "_blank",
            "resizable=yes, scrollbars=yes, titlebar=yes, width=800, height=900, top=10, left=10"
        );
    };

    return (
        <li className="search-item-row">
            <IconAndLink dataObj={dataObj} searchTerm={searchTerm} position={position} />
            <Description dataObj={dataObj} searchTerm={searchTerm} />
            <StatusAndCreated dataObj={dataObj} />
            {isPreviewTest && (
                <EonUiLink
                    icon="fullscreen_enter"
                    iconPosition="left"
                    onClick={() => showPreview(dataObj.link)}
                    text="Preview"
                    title="Preview"
                    size="small"
                ></EonUiLink>
            )}
            <LinkWithFeedback
                className="search-item-link-full"
                href={dataObj.link}
                target="_blank"
                rel="noreferrer"
                position={position}
                query={searchTerm ?? ""}
                index={dataObj.index}
                id={dataObj.id}
            >
                {dataObj.link}
            </LinkWithFeedback>
            <Feedback dataToSend={dataToSend} />
            {useExplainFlag() && (
                <Explain
                    query={searchTerm|| ""}
                    index={dataObj.index}
                    id={dataObj.id}
                />
            )}
        </li>
    );
}

type ElSearchAdvanceCardProps = {
    result: any;
    searchTerm?: string;
    position: number;
};
function ElSearchAdvanceCard({
    result,
    searchTerm,
    position,
}: Readonly<ElSearchAdvanceCardProps>) {
    const dataObj = findDataObject(result.index, result);
    return (
        <RenderElSearchAdvanceCard dataObj={dataObj} searchTerm={searchTerm} position={position} />
    );
}
export default ElSearchAdvanceCard;
