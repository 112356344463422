import React from "react";
import Joyride, { Step, CallBackProps } from "react-joyride";
import { useTranslation } from "react-i18next";
import "./TourStart.scss";
interface TourGuideProps {
    run: boolean;
    onTourEnd: () => void;
    steps: Step[];
}
const TileTour: React.FC<TourGuideProps> = ({ run, onTourEnd, steps }) => {
    const { t } = useTranslation();

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status } = data;
        const finishedStatuses = ["finished", "skipped"];
        if (finishedStatuses.includes(status)) {
            onTourEnd();
        }
    };

    return (
        <div data-testid={run ? "tileTour" : ""}>
            <Joyride
                steps={steps}
                run={run}
                continuous={true}
                scrollToFirstStep={true}
                scrollOffset={80}
                showProgress={true}
                showSkipButton={true}
                callback={handleJoyrideCallback}
                data-testid="tileTour"
                locale={{
                    back: t("tourData.userActions.back"),
                    close: t("tourData.userActions.close"),
                    last: t("tourData.userActions.last"),
                    next: t("tourData.userActions.next"),
                    open: t("tourData.userActions.open"),
                    skip: t("tourData.userActions.skip"),
                }}
                styles={{
                    options: {
                        arrowColor: "#fff",
                        backgroundColor: "#fff",
                        overlayColor: "rgba(0, 0, 0, 0.4)",
                        primaryColor: "#1ea2b1",
                        textColor: "#262626",
                        width: 300,
                        zIndex: 1000,
                    },
                }}
            />
        </div>
    );
};

export default TileTour;
