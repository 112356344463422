import {ReactComponent as Debug} from "../../assets/image/debug.svg";
import React, {CSSProperties, useEffect, useState} from "react";
import {searchContext} from "../../index";
import {makeBoostAndPhraseQuery} from "../../search/boostAndPhrase";
import {useExplainData} from "./explain.context";

export type ExplainButtonProps = {
    onClick: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ExplainButton({onClick}: ExplainButtonProps) {
    return (
        <button onClick={onClick}>
            <Debug/>
        </button>
    );
}

export type ExplainControlCenterProps = {
    query: string | null;
    index: string;

};
const tableStyles: CSSProperties = {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
    border: '1px solid #ddd',
};

const headerCellStyles: CSSProperties = {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    backgroundColor: '#f9f9f9',
    textAlign: 'left',
};

const cellStyles = {
    padding: '10px',
    borderBottom: '1px solid #ddd',
};

const inputStyles: CSSProperties = {
    width: '100%',
    padding: '6px',
    boxSizing: 'border-box',
};

const checkboxStyles: CSSProperties = {
    textAlign: 'center',
};


export function ExplainControlCenter({query, index}: ExplainControlCenterProps) {
    const [id, setId] = useState<string | null>(new URLSearchParams(window.location.search).get('explainId'));
    const [explainData, setExplainData] = useExplainData();

    const containerStyles: CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '5px',
        fontFamily: 'Arial, sans-serif',
        maxWidth: '1000px',  // Increased max width to allow side-by-side layout
        margin: '0 auto',
    };

    const tableContainerStyles: CSSProperties = {
        flex: '1 1 45%',  // Flex-basis with room for flex wrapping
        minWidth: '300px',
    };

    const explainContainerStyles: CSSProperties = {
        flex: '1 1 45%',  // Flex-basis with room for flex wrapping
        minWidth: '300px',
        marginTop: '10px',
    };

    return (
        <div style={containerStyles}>
            <div style={tableContainerStyles}>
                <table style={tableStyles}>
                    <thead>
                    <tr>
                        <th style={headerCellStyles}>Index</th>
                        <th style={headerCellStyles}>The id you want to explore</th>
                        <th style={headerCellStyles}>Explain Depth</th>
                        <th style={headerCellStyles}>Full</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={cellStyles}>{index ? index : 'Need to select data source'}</td>
                        <td style={cellStyles}>
                            <input
                                id="explain-id"
                                type="text"
                                onChange={(e) => setId(e.target.value)}
                                value={id || ''}
                                style={inputStyles}
                            />
                        </td>
                        <td style={cellStyles}>
                            <input
                                id="explain-depth"
                                type="number"
                                onChange={(e) =>
                                    setExplainData({
                                        ...explainData,
                                        explainDepth: Number.parseInt(e.target.value),
                                    })
                                }
                                value={explainData.explainDepth}
                                style={inputStyles}
                            />
                        </td>
                        <td style={{...cellStyles, ...checkboxStyles}}>
                            <input
                                type="checkbox"
                                id="explain-full"
                                onChange={() => setExplainData({...explainData, full: !explainData.full})}
                                checked={explainData.full}
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            {id && index && (
                <div style={explainContainerStyles}>
                    <Explain id={id} index={index} query={query}/>
                </div>
            )}
        </div>
    );
}

export type ExplainProps = {
    query: string | null;
    index: string;
    id: string;
};

export function Explain({query, index, id}: ExplainProps) {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [explain, setExplain] = useState<any>(undefined);
    const [currentQuery, setCurrentQuery] = useState<string | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [esQuery, setEsQuery] = useState<any>(null);


    useEffect(() => {
        if (show && query && !loading && currentQuery !== query) {
            setCurrentQuery(query);
            const body = makeBoostAndPhraseQuery(searchContext.boosts || [])(
                10,
                query,
                []
            );
            delete body.size;
            delete body._source;
            delete body.sort;
            delete body.aggregations;
            setEsQuery(body);
            const url = searchContext.elasticsearchUrl(index, true) + "/" + id;
            searchContext
                .rawCallElasticSearch(url, body, searchContext.apikey())
                .then((res) => {
                    setExplain({...res.data, query, esQuery: body});
                })
                .catch((e: any) => {
                    console.error(
                        `Error calling elastic search for explain`,
                        e
                    );
                    setError(`Error calling Elasticsearch`);
                })
                .finally(() => setLoading(false));
        }
    }, [show, loading, query, explain]);
    return (
        <>
            {show && error && <pre>{error}</pre>}
            {show && loading && <pre>Loading Explain for {query}</pre>}
            <ExplainButton onClick={() => setShow(!show)}/>
            {show && explain && <ExplanationTable explanation={explain}/>}
        </>
    );
}

// Define the ExplanationDetail type
type ExplanationDetail = {
    value: number;
    description: string;
    details?: ExplanationDetail[];
};
const tableStyle: CSSProperties = {
    width: '100%',
    borderCollapse: 'collapse',
    margin: '20px 0',
    fontSize: '16px',
    textAlign: 'left',
};

const thStyle: CSSProperties = {
    borderBottom: '2px solid #ddd',
    padding: '8px',
    backgroundColor: '#f2f2f2',
};

const tdStyle: CSSProperties = {
    border: '1px solid #ddd',
};

// Recursive function to render the explanation details in the table
const renderDetails = (
    details: ExplanationDetail[],
    level: number = 0
): JSX.Element[] => {
    const [explainData] = useExplainData();
    const show = (detail: ExplanationDetail) => {
        const description = detail.description.trim();
        const included = ['sum of:', 'max of:'].includes(description);
        const result = explainData.full || !included;
        return result;
    };
    return details.map((detail, index) => (
        <React.Fragment key={`${level}-${index}`}>
            {show(detail) && <tr>
                <td style={tdStyle}>{''.padEnd(level * 2, '.')}{detail.value}</td>
                <td style={tdStyle}>{detail.description}</td>
            </tr>}
            {detail.details &&
                detail.details.length > 0 &&
                level < explainData.explainDepth &&
                renderDetails(detail.details, level + 1)}
        </React.Fragment>
    ));
};

// Props type for the ExplanationTable component
interface ExplanationProps {
    explanation: {
        esQuery: any;
        query: string
        _index: string;
        _id: string;
        explanation: ExplanationDetail
    };
}

function ExplanationTable({explanation}: ExplanationProps) {
    const copyToClipboard = (thing: any) => {
        const jsonData = JSON.stringify(thing, null, 2);
        navigator.clipboard.writeText(jsonData)
            .then(() => alert('Copied to clipboard!'))
            .catch(err => console.error('Failed to copy!', err));
    };
    return (
        <div>
            <h2>Explanation for Document<span
                style={{cursor: 'pointer', marginLeft: '10px'}}
                onClick={() => copyToClipboard(explanation)}
                title="Copy Explaination to clipboard"
            >📋</span><span
                style={{cursor: 'pointer', marginLeft: '10px'}}
                onClick={() => copyToClipboard(explanation.esQuery)}
                title="Copy Query to clipboard"
            >Q</span></h2>
            <table style={tableStyle}>
                <thead>
                <tr>
                    <th style={thStyle}>Value</th>
                    <th style={thStyle}>Description</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td style={tdStyle}>{explanation._index}</td>
                    <td style={tdStyle}>{explanation._id}</td>
                </tr>
                <tr>
                    <td style={tdStyle}>Relevance</td>
                    <td style={tdStyle}>{explanation.explanation.value}</td>

                </tr>
                {renderDetails([explanation.explanation])}
                </tbody>
            </table>
        </div>
    );
}

