import React, { useState } from "react";
import SVGIcon from "../svg-icon/SVGIcon";

type Location = {
    location_id: number;
    location_type_id: number;
    location_name: string;
    hierarchy_location_ids: number[];
    children?: Location[];
    isOpen?: boolean;
    isHome: boolean;
    hierarchy_level?: number;
};

const HierarchyNode: React.FC<{
    node: Location & { children?: Location[] };
    clickedLocation: (
        ids: number[],
        name: string,
        closeDropDown?: boolean
    ) => void;
    clickedFloor: string;
}> = ({ node, clickedLocation, clickedFloor }) => {
    const [isOpen, setIsOpen] = useState(node?.isOpen || false);

    const country: Record<number, string> = {
        3: "germany",
        65161: "romania",
        68708: "hungary",
        68144: "luxembourg",
        70766: "norway",
        4691: "czech_republic",
        56877: "united_kingdom",
        51017: "poland",
        70768: "denmark",
        61244: "sweden",
        1214: "netherlands",
    };

    const icons: Record<number, Array<string>> = {
        40: ["Continent", "eu"], // "Continent",
        2: ["Country", "country"], // "Country"
        29: ["State", "state"], // "State"
        16: ["City", "city"], // "City"
        22: ["Street", "street"], // "Street"
        17: ["Building", "building"], // "Building"
        18: ["Floor", "floor"], // "Floor"
        43: ["Area", "area"], // "Area"
        224: ["Area", "area"], // "Area"
    };

    const handleToggle = (node: Location) => {
        setIsOpen(!isOpen);
        const isLastChild = node && node.children && node.children.length < 1;
        const isEndOfHierarchy = (node.hierarchy_level || 0) >= 7;
        if (isLastChild || isEndOfHierarchy) {
            clickedLocation(
                [...node.hierarchy_location_ids, node.location_id],
                node.location_name,
                isLastChild
            );
        } else {
            clickedLocation([], "");
        }
    };

    return (
        <div style={{ marginLeft: "8px" }}>
            <button
                onClick={(event) => {
                    event.stopPropagation();
                    handleToggle(node);
                }}
                className={`collapsible ${
                    clickedFloor === node.location_name
                        ? "collapsible--selected"
                        : ""
                } ${node.isHome ? "collapsible--isHome" : ""}`}
            >
                {node.location_type_id === 2 ? (
                    <SVGIcon url={`./icons/${country[node.location_id]}.svg`} />
                ) : (
                    <SVGIcon
                        url={`./icons/${icons[node.location_type_id][1]}.svg`}
                    />
                )}
                <span className="location-name">{node.location_name}</span>
                <span className="home-icon">
                    {node.isHome && <SVGIcon url={`./icons/home.svg`} />}
                </span>
            </button>
            {isOpen && node.children && node.children.length > 0 && (
                <div className="content">
                    {node.children.map((child: Location) => {
                        return (
                            <HierarchyNode
                                key={child.location_id}
                                node={child}
                                clickedLocation={clickedLocation}
                                clickedFloor={clickedFloor}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default HierarchyNode;
