import UserIcon from "../../../assets/image/user.svg";
import {MessageWithActionAndData} from "../AiAssist.context";
// import useFirstName from "../hooks/useFirstName";

const userQueryComponent = ({content}: MessageWithActionAndData) => {
    // const {firstName} = useFirstName();
    return (
        <div className="user-header">
            <div className="user-logo">
                <img src={UserIcon} alt="userIcon"/>
            </div>
            <div className="user-reply-container">
                <div className="bot-text">{content}</div>
            </div>
        </div>
    );
};

export default userQueryComponent;