import {EnvironmentConfig} from "../config/EnvironmentConfig";
import axios from "axios";

export const getChatApi = async(
    payload: any,
    headers: any
): Promise<any> => {
    console.log("Get Chat API", payload);
    const url = `${EnvironmentConfig.gatewayURL}/api/live-agent/messages?clientSessionID=${payload.sessionId}&updatedAt=${payload.timestamp}`;
    return await ApiCall(
        url,
        "GET",
        {},
        headers
    );
};

export const createChatApi = async(
    payload: any,
    headers: any
): Promise<any> => {
    console.log("Create Chat API", payload);
    return await ApiCall(
        `${EnvironmentConfig.serviceUrl}/cognigy-chatbot-api/v1/meeonchatbot/api/ieon/connect_chat_integration/insert`,
        "POST",
        payload,
        headers
    );
};

export const updateChatApi = async(
    payload: any,
    headers: any
): Promise<any> => {
    console.log("Update Chat API", payload);
    return await ApiCall(
        `${EnvironmentConfig.serviceUrl}/cognigy-chatbot-api/v1/meeonchatbot/api/ieon/connect_chat_integration/update`,
        "PUT",
        payload,
        headers
    );
};

export const closeChatApi = async(
    payload: any,
    headers: any
): Promise<any> => {
    console.log("Close Chat API", payload);
    return await ApiCall(
        `${EnvironmentConfig.serviceUrl}/cognigy-chatbot-api/v1/meeonchatbot/api/ieon/connect_chat_integration/close`,
        "PUT",
        payload,
        headers
    );
};

const ApiCall = (
    url: string,
    methodType: string,
    payLoad: string | object,
    headers: any
) => {
    return axios({
        method: methodType,
        url,
        data: typeof payLoad === "object" ? JSON.stringify(payLoad) : "{}",
        headers,
    })
        .then((response) => {
            console.log("success response", response);
            return response ? response.data : false;
        })
        .catch((error) => {
            console.error("Fetch Error :-S", error);
            return false;
        });
};