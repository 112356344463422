import {SearchFn} from "./search";
import {ESApiFinalResponseInterface} from "../interfaces/ElasticSearchInterface";
import {PublicClientApplication} from "@azure/msal-browser";
import {sanitize} from "../util/strings";

const searchBodyForGraphApi = (firstIndex: string, query: string) => {
    function filter(): any {
        console.log("firstIndex", firstIndex);
        if (firstIndex === "m365")
            return {};
        throw new Error(`Unknown index ${firstIndex}`);
    }

    function entityTypes(): string[] {
        if (firstIndex === "m365")
            return ["driveItem"];
        if (firstIndex ==='m365-teams') //not used. Left for future use
            return ["chatMessage"];
        if (firstIndex === 'm365-emails')
            return ["message"];
        throw new Error(`Unknown index ${firstIndex}`);
    }
    return ({
        requests: [
            {
                entityTypes: entityTypes(),
                query: {
                    queryString: sanitize(query), //reduce chance of injection attack. Not perfect
                },
                selectProperties: [
                    "name", // File name
                    "webUrl", // URL to the file
                    "createdDateTime", // When the file was created
                    "lastModifiedDateTime", // Last modification date
                    "size", // File size in bytes
                    "file", // File-specific metadata (e.g., MIME type)
                    "parentReference", // Information about the parent folder
                    "hitHighlightedSummary", // Highlighted content around search term
                ],
                ...filter()
            },
        ],
    });
};

export function defaultGraphApiTokenFn(
    scopes: string[],
    msalInstance: PublicClientApplication
): () => Promise<string> {
    return async () => {
        const account = msalInstance.getAllAccounts()[0];
        if (!account) throw new Error("No account found");
        const response = await msalInstance.acquireTokenSilent({
            scopes,
            account,
        });
        return response.accessToken;
    };
}

export function graphApiSearch(
    token: () => Promise<string>
): SearchFn<ESApiFinalResponseInterface> {
    return async (query) => {
        const firstIndex = query.searchIndexes[0];

        const headers = {
            Authorization: `Bearer ${await token()}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        };
        const resp = await fetch(
            `https://graph.microsoft.com/v1.0/search/query`,
            {
                method: "POST",
                headers,
                body: JSON.stringify(searchBodyForGraphApi(firstIndex, query.searchTerm)),
            }
        );
        if (!resp.ok)
            throw new Error(
                `Failed to search: ${resp.statusText}\n${await resp.text()}`
            );
        const json = await resp.json();
        const containers = json.value.flatMap((v: any) =>
            v.hitsContainers.flatMap((hc: any) => hc.hits || [])
        );
        return containers.map((c: any) => ({...c, index: "m365"}));
    };
}
