import {appInfo} from "./info/appInfo";
import {confluenceInfo} from "./info/confluenceInfo";
import {gitlabInfo} from "./info/gitlabInfo";
import {jiraInfo} from "./info/jiraInfo";
import {myGeniusInfo} from "./info/myGeniusInfo";
import {itOrderInfo} from "./info/itOrderInfo";
import {sharepointInfo} from "./info/sharepointInfo";
import {knowledgeInfo} from "./info/knowledgeInfo";
import {pageInfo} from "./info/pageInfo";
import {userInfo} from "./info/userInfo";
import {SelectedMetadataInterface} from "../interfaces/ElasticSearchInterface";
import {m365Info} from "./info/m365Info";
import {jqlInfo} from "./info/jqlInfo";
import { EnvironmentConfig } from "../config/EnvironmentConfig";

export type DropDownInfo = {
    label: string;
    value: string;
    icon: string;
};
export type TitleTargetAndSource = {
    title: string;
    target: string;
    source: string | undefined;
};
export type FilterDateRange = {
    start: string;
    now: string;
};
export type SourceInfo = {
    match: string;
    dropDown?: DropDownInfo;
    titleAndTarget: (result: any) => TitleTargetAndSource;
    dateFilter?: (index: string, dates: FilterDateRange) => any;
    makeDataObject: (result: any) => DataObject;
    restrictPdfTitles?: boolean;
    dateFilterKey?: string;
    refineFilterLabels?: (key: string, value: any) => any;
    refineFiltersAggregationQuery?: object;
    createMetadataFilters?: (criteria: SelectedMetadataInterface) => any;
};

export const dataFilterOrJustIndex =
    (sources: SourceInfo[], dates: FilterDateRange | undefined) =>
        (index: string): any => {
            const source = sources.find((s) => index.match(s.match));
            return dates && source?.dateFilter
                ? source.dateFilter(index, dates)
                : {filter: [{term: {_index: index}}]};
        };
export type FilterQuery = (
    indicies: string[],
    dates: FilterDateRange,
    sources?: SourceInfo[]
) => any;
export const filterQuery: FilterQuery = (
    indicies: string[],
    dates: FilterDateRange,
    sources?: SourceInfo[]
): any => ({
    should: indicies.map(
        dataFilterOrJustIndex(sourcesOrDefault(sources), dates)
    ),
});

export const defaultSourceInfo: SourceInfo[] = [
    appInfo,
    confluenceInfo,
    gitlabInfo,
    jiraInfo,
    jqlInfo,
    m365Info,
    pageInfo,
    userInfo,
    myGeniusInfo,
    ...(EnvironmentConfig.showItOrderFilter ? [itOrderInfo] : []),
    sharepointInfo,
    knowledgeInfo,
];

export const refineFiltersAggregationQuery = {
    ...confluenceInfo.refineFiltersAggregationQuery,
    ...gitlabInfo.refineFiltersAggregationQuery,
    ...jiraInfo.refineFiltersAggregationQuery,
    ...myGeniusInfo.refineFiltersAggregationQuery,
    ...sharepointInfo.refineFiltersAggregationQuery,
    ...knowledgeInfo.refineFiltersAggregationQuery,
};

export const createTermsQuery = (selectedMetadata: SelectedMetadataInterface, key: string) => {
    if (!selectedMetadata[key].length) return null;
    return {
        terms: {
            [`${key}.keyword`]: selectedMetadata[key].split(", ")
        }
    };
};

export const createRegexQuery = (selectedMetadata: SelectedMetadataInterface, key: string) => {
    if (!selectedMetadata[key].length) return null;
    return {
        regexp: {
            [key]: `.*(${selectedMetadata[key].split(", ").join("|")}).*`
        }
    };
};

export function sourcesOrDefault(sources?: SourceInfo[]) {
    return sources ?? defaultSourceInfo;
}

export function findSourceInfo(
    match: string,
    sources?: SourceInfo[]
): SourceInfo {
    const theSources = sourcesOrDefault(sources);
    const result = theSources.find((source) => match.match(source.match));
    if (result == undefined) {
        throw new Error(
            `No source info found for match: ${match}. Legal values are ${theSources.map(
                (s) => s.match
            )}`
        );
    }
    return result;
}

export function findDataObject(
    match: string,
    result: any,
    sources?: SourceInfo[]
): DataObject {
    const maker = findSourceInfo(match, sources);
    return maker.makeDataObject(result);
}

export function findTitleTarget(result: any): TitleTargetAndSource {
    return (
        findSourceInfo(result.index)?.titleAndTarget?.(result) || {
            title: "",
            target: "",
            source: "",
        }
    );
}

export function findDropDown(sources?: SourceInfo[]): DropDownInfo[] {
    return sourcesOrDefault(sources)
        .filter((s) => s.dropDown)
        .map((s) => s.dropDown) as DropDownInfo[];
}

export function showDateFilters(
    match: string,
): boolean {
    if (match == undefined||match === "") return false;
    return findSourceInfo(match).dateFilterKey !== undefined;
}

export type DataObject = {
    id: string;
    index: string;
    icon: string;
    link: string;
    name: string;
    type?: string | null;
    breakcrum: string;
    status: string;
    desc: string;
    document: string;
    createdBy: string;
    lastUpdate: string;
    createdAt: string;
    duration?: string | null;
    language?: string | null;
    deliveryTime?: string | null;
    category?: string | null;
    source?: string;
    render?: "markdown" | "html";
    alias?: string;
};
export const emptyDataObject: DataObject = {
    id: "",
    index: "",
    icon: "",
    link: "",
    name: "",
    type: "",
    breakcrum: "",
    status: "",
    desc: "",
    document: "",
    createdBy: "",
    lastUpdate: "",
    createdAt: "",
};