import {DataObject, emptyDataObject, SourceInfo} from "../info";

export const jqlInfo: SourceInfo = {
    match: "jql",
    dropDown: undefined,
    titleAndTarget: (result: any) => ({
        title: `someTitle ${JSON.stringify(result)}`,
        target: result?.target,
        source: undefined,
    }),
    makeDataObject: (c: any) => {
        const result: DataObject = {
            //needs to be done for jql results
            ...emptyDataObject,
            id: c.resource.id,
            name: c.resource.name,
            link: c.resource.webUrl,
            createdAt: c.resource.createdDateTime,
            lastUpdate: c.resource.lastModifiedDateTime,
            index: "jql",
            render: "html",
            desc: c.summary,
        };
        return result;
    },
};
