import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
    page: string;
};
const CustomRedirect: React.FC<Props> = ({ page }: Props) => {
    const navigate = useNavigate();

    useEffect(() => {
        window.location.href = `/${page}${window.location.search}`;
    }, [navigate]);

    return null;
};

export default CustomRedirect;
