import { t } from "i18next";
import useFirstName from "../hooks/useFirstName";
import { ReactComponent as AiIcon } from "../../../assets/image/ai-icon64.svg";

const AiWelcomeWrapper = () => {
    const { firstName } = useFirstName();

    return (
        <>
            <div className="ai-assist-welcome">
                <AiIcon />
                <div>
                    <span
                        title={t("chatBot.topicStartMessage")}
                        key={t("chatBot.topicStartMessage")}
                        className="start-message"
                    >
                        {t("chatBot.hey")} {firstName},{" "}
                        {t("chatBot.topicStartMessage")}
                    </span>
                </div>
            </div>
        </>
    );
};

export default AiWelcomeWrapper;
