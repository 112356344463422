import {refineFiltersAggregationQuery} from "../domain/info";
import {MakeQueryForEsFn} from "./search";

export const getReqBody = (
    searchTerm: string,
    additionalFilters: any,
    boosts: string[],
    resultSize?: number,
    searchAfter?: object
) => {
    const newQuery = searchTerm.replace(/\^/g, '');
    return {
        size: resultSize,
        // from: 0,
        _source: {
            excludes: [
                "full_text_embeddings",
                "full_text",
                "file",
                "pdf file path",
                "pdf",
            ],
        },
        query: {

            bool: {
                must: [
                    {
                        dis_max: {
                            queries: [
                                {
                                    multi_match: {
                                        "query": `*${newQuery}*`,
                                        fields: boosts,
                                        type: "best_fields",
                                        boost: 1.5
                                    }
                                },
                                {
                                    "query_string": {
                                        type: 'phrase_prefix',
                                        "default_field": "full_text",
                                        "query": `*${newQuery}*`,
                                        "boost": 1.5,
                                        "default_operator": "AND",
                                        "phrase_slop": 1
                                    }
                                },
                                {
                                    "query_string": {
                                        "query": newQuery,

                                        "boost": 1,
                                        "default_operator": "OR",
                                    }
                                }
                            ]
                        }
                    }
                ],
                ...(additionalFilters && {...additionalFilters}),
            }
        },
        sort: [
            {
                _score: {
                    order: "desc",
                },
            },
            {
                _id: {
                    order: "asc",
                },
            },
        ],
        aggregations: {
            count: {
                terms: {
                    field: "_index",
                },
            },
            ...refineFiltersAggregationQuery,
        },
        ...(searchAfter && {search_after: searchAfter}),
    };
};
export const makeBoostAndPhraseQuery =
    (boosts: string[]): MakeQueryForEsFn =>
        (
            resultSize: number,
            searchTerm: string,
            additionalFilters: any,
            searchAfter?: object,
        ) =>
            getReqBody(searchTerm, additionalFilters, boosts, resultSize, searchAfter);
