import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
 
interface ScreenWidthContextType {
    screenWidth: number;
    isMobile: boolean;
}
 
const ScreenWidthContext = createContext<ScreenWidthContextType | undefined>(undefined);
 
interface ScreenWidthProviderProps {
    children: ReactNode;
}
 
export const ScreenWidthProvider: React.FC<ScreenWidthProviderProps> = ({ children }) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [isMobile, setIsMobile] = useState<boolean>(true);
    
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (screenWidth) {
            const mobile = screenWidth < 1024 ? true : false;
            setIsMobile(mobile);
        }
    }, [screenWidth]);
 
    return (
        <ScreenWidthContext.Provider value={{screenWidth, isMobile}}>
            {children}
        </ScreenWidthContext.Provider>
    );
};
 
export const useScreenWidth = (): ScreenWidthContextType => {
    const context = useContext(ScreenWidthContext);
    if (!context) {
        throw new Error("useScreenWidth must be used within a ScreenWidthProvider");
    }
    return context;
};