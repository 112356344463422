import {createContext, useContext} from "react";
import {AxiosStatic} from "axios";
import {TimeService} from "../../domain/useTime";
import {envName} from "../../util/common-util";
import {EnvironmentConfig} from "../../config/EnvironmentConfig";

export type UniqueUserDetails = {
    ctry: string;
    CompanyName: string;
}
export type UniqueUsersFn = (email: string, details: UniqueUserDetails) => Promise<void>;

export async function defaultEmailToHash(email: string): Promise<string> {
//Note this salt is not a security enforcing function. It's just a salt to ensure that the kids are not visible to casual readers
//Given this is client side we can't enforce security here: it just to make it a bit harder to find the kids
    const withSalt = `${email}dfgdhkj*)_(fdghk 1234`;

    // Calculate the SHA-256 hash
    const encoder = new TextEncoder();
    const data = encoder.encode(withSalt);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);

    // Convert the hash to a Base64 string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const base64Hash: string = btoa(hashArray.map((byte) => String.fromCharCode(byte)).join(""));

    return base64Hash;
}

export const UniqueUsersContext = createContext<UniqueUsersFn | undefined>(undefined);

export type LoggedInPayload = UniqueUserDetails & {
    hash: string;
    env: string;
    '@timestamp': number
}


type UniqueUsersProviderProps = {
    children: React.ReactNode;
    uniqueUsers: UniqueUsersFn;
}

export function UniqueUsersProvider({children, uniqueUsers}: UniqueUsersProviderProps) {
    return <UniqueUsersContext.Provider value={uniqueUsers}>{children}</UniqueUsersContext.Provider>;
}

export function useUniqueUsers(): UniqueUsersFn {
    const uniqueUsers = useContext(UniqueUsersContext);
    if (uniqueUsers === undefined) throw new Error("useUniqueUsers must be used within a UniqueUsersProvider");
    return uniqueUsers;
}


export function defaultUniqueUsersFn(axios: AxiosStatic, index: string, timeService: TimeService, emailToHash: (email: string) => Promise<string>): UniqueUsersFn {
    return async (email: string, details: UniqueUserDetails): Promise<void> => {
        const {ctry, CompanyName} = details || {};
        const payload: LoggedInPayload = {
            ctry, CompanyName,
            hash: await emailToHash(email),
            env: envName(),
            '@timestamp': timeService().getTime()
        };
        return axios.post(`${EnvironmentConfig.elasticSearchBaseUrl}/${index}/_doc`, payload, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `ApiKey ${localStorage.getItem("dls-apikey")}`,
            },
        });
    };
}