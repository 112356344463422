import {AdminButton} from "./adminButton";

export type EnvsProps = {
    envs: string[]
    env: string
    setEnv: (env: string) => void
}

export function Envs({envs, env, setEnv}: EnvsProps) {
    return <>{envs.map((e) => <AdminButton key={e} text={e} selected={e === env} onClick={() => setEnv(e)}/>)}</>;
}