import {useEffect, useState} from "react";
import {SelectFilterButton} from "./SelectFilterButton";
import {EonUiIcon,} from "@eon-ui/eon-ui-components-react";
import {useTranslation} from "react-i18next";
import "./SearchFiltersRight.scss";
import DataSourceFilters from "./DataSourceFilters";
import Modal from "../modal/Modal";
import SearchFiltersRightModal from "./SearchFiltersRightModal";
import SearchRefineResult from "./SearchRefineResult";
import {SearchFiltersInterface, SelectedMetadataInterface} from "../../interfaces/ElasticSearchInterface";
import {useTabFilter} from "./TabFilterStrategy";
import {useFilterDebugFlag} from "../../hooks/useFilterDebugFlag";

const SearchFiltersRight = (props: SearchFiltersInterface) => {
    const {
        setters,
        searchIndex,
        additionalFilters,
        metadataFilters,
        dataSourceFilters,
        filterCount,
    } = props;

    const tabFilterStrategy=useTabFilter(searchIndex);
    const {
        // setSearchIndex,
        setDataSourceFilters,
        setFilterCount,
    } = setters;

    const { t } = useTranslation();
    const [searchFiltersRightModal, setSearchFiltersRightModal] = useState<boolean>(false);

    useEffect(() => {
        setFilterCount(calculateFilterCount());
    }, [searchIndex, metadataFilters]);

    const calculateFilterCount = () => {
        let count: number = searchIndex.length ? 1 : 0;
        if (additionalFilters.selectedTime) {
            return ++count;
        }
        for (const key in additionalFilters.selectedMetadata) {
            if (additionalFilters.selectedMetadata[key as keyof SelectedMetadataInterface[]]) {
                return ++count;
            }
        }
        return count;
    };

    const toggleMenu = () => {
        window.innerWidth < 1024 && setSearchFiltersRightModal(true);
    };

    if (dataSourceFilters.length === 0) {
        return null;
    }


    return (
        <div className="search-filter-wrapper">
            <div className="search-filter-header">
                <div className="filter-header-left" onClick={toggleMenu}>
                    <EonUiIcon
                        className="eon-ui-svg-icon"
                        scheme="red500"
                        placeholder="funnel-img"
                        size="normal"
                        key={"funnel-icon"}
                        name="funnel"
                    ></EonUiIcon>
                    <span className="filter-heading">{t("filterPage.filterMenu.filterHeading")}</span>
                    <div className="arrow-container">
                        {searchIndex.length > 0 && (<div className="filter-count">+{filterCount}</div>)}
                        <EonUiIcon
                            name={
                                searchFiltersRightModal ? "chevron_small_up" : "chevron_small_down"
                            }
                            className="arrow eon-ui-svg-icon"
                        ></EonUiIcon>
                    </div>
                </div>
                <SelectFilterButton
                    isReset={true}
                    setters={setters}
                    textkey="filterPage.filterMenu.reset"
                    dataType="all"
                    value={[]}
                    clearActiveFilter={()=>setDataSourceFilters([...dataSourceFilters])}
                />
            </div>
            <div className="filter-search-section">
                {dataSourceFilters.length > 0 && (
                    <>
                        <div className="filter-section-heading">{t("filterPage.filterMenu.datasources")}</div>
                        {useFilterDebugFlag()&&<pre>show [{tabFilterStrategy.showNumbers?'true':'false'}], {tabFilterStrategy.searchIndicies}</pre>}
                        <DataSourceFilters
                            tabFilterStrategy={tabFilterStrategy}
                            setters={setters}
                            dataSourceFilters={dataSourceFilters}
                            searchIndex={searchIndex}
                        />
                    </>
                )}
                <SearchRefineResult { ...props } />
            </div>

            <Modal
                toggleModal={searchFiltersRightModal}
                closeModal={() => setSearchFiltersRightModal(!searchFiltersRightModal)}
                isCloseIcon
                closeOnBackdrop={false}
                >
                <SearchFiltersRightModal setSearchFiltersRightModal={setSearchFiltersRightModal} {...props} />
            </Modal>
        </div>
    );
};
export default SearchFiltersRight;
