import {ellipses} from "../../util/strings";
import {emptyDataObject, SourceInfo} from "../info";


export const knowledgeInfo: SourceInfo = {
    match: "azureblob",
    dropDown: undefined,
    titleAndTarget: (result) => ({
        title: result.title ?? result.id,
        target: result.url,
        source: "Knowledge Base",
    }),
    makeDataObject: (result) => ({
        ...emptyDataObject,
        id: result.id,
        index: result.index,
        icon: "knowledge",
        link: `${result.url}?${process.env.REACT_APP_AZURE_BLOB_ACCESS_KEY}`,
        name: result.title ?? result.id,
        source: "Knowledge Base",
        desc: ellipses(result.body, 200),
        render: "markdown",
    }),
};
