import { useEffect, useState } from "react";
import { SelectFilterButton } from "./SelectFilterButton";
import {
    EonUiDropdown,
    EonUiDropdownOption,
    EonUiIcon,
} from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import "./SearchFiltersRight.scss";
import { showDateFilters } from "../../domain/info";
import { SearchFiltersInterface, TimeRangeOptionsInterface } from "../../interfaces/ElasticSearchInterface";
import { getTimeInSeconds } from "../../util/date";

const SearchRefineResult = (props: SearchFiltersInterface) => {
    const {
        setters,
        searchIndex,
        additionalFilters,
        metadataFilters
    } = props;

    const {
        setIsLoading,
        setResultCount,
        setResultsToDisplay,
        setAdditionalFilters,
        setScrollCompleted,
    } = setters;

    const { t } = useTranslation();

    const [enableDateFilters, setEnableDateFilters] = useState<boolean>(false);
    const [enableMetadataFilters, setEnableMetadataFilters] = useState<boolean>(false);

    const timeRangeOptions = [
        { label: "All", value: "", icon: "", default: true },
        { label: "1 Day", value: "1", icon: "" },
        { label: "7 Days", value: "7", icon: "" },
        { label: "1 Month", value: "30", icon: "" },
        { label: "6 Months", value: "180", icon: "" },
        { label: "1 Year", value: "365", icon: "" },
    ];

    useEffect(() => {
        setEnableDateFilters(showDateFilters(searchIndex[0]));
        const enableMetadataFilters: boolean = searchIndex.length > 0  && metadataFilters && Object.keys(metadataFilters).length > 0;
        setEnableMetadataFilters(enableMetadataFilters);
    }, [searchIndex, metadataFilters]);

    const updateMetadataFilters = (value: string, item: string) => {
        setIsLoading(true);
        setScrollCompleted(false);
        setResultsToDisplay([]);
        setResultCount(0);
        setAdditionalFilters({ ...additionalFilters, selectedMetadata: { ...additionalFilters.selectedMetadata, [item]: value } });
    };

    return (
        <div className="refine-filters">
            {(enableDateFilters || enableMetadataFilters) ? (
                <div className="search-filter-header refine-filters-container">
                    <div className="filter-header-left">
                        <EonUiIcon
                            className="eon-ui-svg-icon"
                            scheme="red500"
                            placeholder="funnel-img"
                            size="normal"
                            key={"funnel-icon"}
                            name="filter"
                        ></EonUiIcon>
                        <span className="filter-heading">{t("filterPage.filterMenu.refineResults")}</span>
                    </div>
                    <SelectFilterButton
                        isReset={true}
                        setters={setters}
                        textkey="filterPage.filterMenu.reset"
                        dataType="all"
                        value={searchIndex}
                    />
                </div>
            ) : <></>}
            {enableDateFilters && (
                <EonUiDropdown
                    onValueChanged={(e) => {
                        setIsLoading(true);
                        setScrollCompleted(false);
                        setResultsToDisplay([]);
                        setResultCount(0);
                        setAdditionalFilters({ ...additionalFilters, selectedTime: e.target.value });
                    }}
                    onDropdownOpened={(e) => {
                        e.target.style.zIndex = getTimeInSeconds().toString();
                    }}
                    onDropdownClosed={(e) => {
                        e.target.style.removeProperty('z-index');
                    }}
                    value={additionalFilters?.selectedTime?.toString() ?? ""}
                    name="time_range"
                    size="normal"
                    label={t("filterPage.filterMenu.timeRange")}
                    showSelectedItems={true}
                >
                    {timeRangeOptions.map((item: TimeRangeOptionsInterface, index: number) => (
                        <EonUiDropdownOption key={index} value={item.value} label={t(`filterPage.timeRangeOptions.${item.label}`)} />
                    ))}
                </EonUiDropdown>
            )}
            {enableMetadataFilters && typeof metadataFilters == "object" && Object.keys(metadataFilters).map((item: any, index: number) => metadataFilters[item].value?.length > 0 && (
                <EonUiDropdown
                    onValueChanged={(e) => {
                        updateMetadataFilters(e.target.value, item);
                    }}
                    onDropdownOpened={(e) => {
                        e.target.style.zIndex = getTimeInSeconds().toString();
                    }}
                    onDropdownClosed={(e) => {
                        e.target.style.removeProperty('z-index');
                    }}
                    value={additionalFilters?.selectedMetadata?.[item] ? additionalFilters?.selectedMetadata?.[item].toString() : ""}
                    name={item}
                    size="normal"
                    label={t(`filterPage.filterMenu.${item}`)}
                    key={`${item}-${index}`}
                    multiselect={true}
                    showSelectedItems={true}
                    height="400px"
                >
                    {metadataFilters[item].value.map((option: any, indexInner: number) => (
                        <EonUiDropdownOption key={`${item}-${index}-${indexInner}`} value={option.key} label={option.label} />
                    ))}
                </EonUiDropdown>
            ))}
        </div>
    );
};
export default SearchRefineResult;
