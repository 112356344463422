import {useContext} from "react";
import {AiAssistContext} from "../AiAssist.context";
import { EonUiDropdown, EonUiDropdownOption } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";

const SliderButton = () => {
    const { t } = useTranslation();
    const {handleChangeCurrentCardKey, currentCardKey} =
        useContext(AiAssistContext);
    const handleButtonClick = (key: string) => {
        if (currentCardKey !== key) {
            return handleChangeCurrentCardKey(key);
        }
    };

    return (
        <>
            <div className="button-toggle-desktop">
                <button
                    title={t("chatBot.askTooltip.eon")}
                    className={`opt-button ${
                        currentCardKey === "selfService" ? "opt-button--active" : ""
                    }`}
                    onClick={handleButtonClick("selfService")}
                >
                    {t("chatBot.askEon")}
                </button>
                <button
                    title={t("chatBot.askTooltip.internet")}
                    className={`opt-button ${
                        currentCardKey === "internetSearch" ? "opt-button--active" : ""
                    }`}
                    onClick={handleButtonClick("internetSearch")}
                >
                    {t("chatBot.askInternet")}
                </button>
            </div>
            <div className="button-toggle-mobile">
                <EonUiDropdown
                    onValueChanged={(e) => {
                        console.log("??", e.target.value);
                        handleButtonClick(e.target.value);
                    }}
                    name="toggle-button"
                    size="small"
                    label="ASK"
                >
                    <EonUiDropdownOption key="ask-eon" value="selfService" label="E.ON" />
                    <EonUiDropdownOption key="ask-internet" value="internetSearch" label="Internet" />
                </EonUiDropdown>
            </div>
        </>
    );
};

export default SliderButton;
