import axios from "axios";
import {useState} from "react";
import {JwtPayload, jwtDecode} from "jwt-decode";
import {useTime} from "../../domain/useTime";
import {useAuth} from "../../MsalContext";

export const tokenData = (token: string | undefined) => {
    try {
        if (!token) return "No token";
        const decoded: JwtPayload = jwtDecode(token);
        const now = new Date();
        const expired = decoded.exp
            ? new Date(decoded.exp * 1000) < now
            : false;
        const expire = decoded.exp
            ? new Date(decoded.exp * 1000).toLocaleString()
            : "No expiry";
        const issuedAt = decoded.iat
            ? new Date(decoded.iat * 1000).toLocaleString()
            : "Not issued";
        return {
            ...decoded,
            expired,
            expire,
            issuedAt,
            now: now.toLocaleString(),
            email: (decoded as any).preferred_username,
        };
    } catch (error) {
        return `Invalid token or error decoding ${error}`;
    }
};

export function useJwtTokens() {
    const {account} = useAuth();
    return {data: tokenData(account?.idToken), token: account?.idToken};
}

let lastDiagnostic: Promise<any> | null = null;
let lastTime: number = 0;
let count = 0;
let localCount = 0;
let gotDetails = 0;

export type DiagnosticsConfig = {
    staleness?: number
    apikey?: string
    showJwtToken?: boolean // defaults true
}

export function useDiagnostics(config?: DiagnosticsConfig): Promise<any> {
    const {staleness = 10000} = config || {};
    const time = useTime();
    const now = time().getTime();
    if (lastDiagnostic === null || now - lastTime > staleness) {
        const apikey = config?.apikey || localStorage.getItem("dls-apikey");
        const url = window.location.href.includes("localhost")
            ? `https://dev-me8on-mvp.eon.tools`
            : "";
        lastDiagnostic = axios
            .get(`${url}/apikey/check/${apikey}`)
            .then((res) => {
                const data = res.data;
                const {results, ...rest} = data;
                return {
                    ...rest,
                    permissions: Object.values(results || {})[0],
                };
            });
        lastDiagnostic.then(() => gotDetails++);
        lastTime = now;
    }
    return lastDiagnostic as Promise<any>;
}

export type DiagnosticsProps = {
    config?: DiagnosticsConfig
}

export function Diagnostics({config}: DiagnosticsProps) {
    count++;
    const [diagnostics, setDiagnostics] = useState<any>("No apikey");
    const jwtTokens = useJwtTokens();
    localCount++;
    useDiagnostics(config).then((d) => {
        if (d !== diagnostics) setDiagnostics(d);
    });
    return (
        <div>
            {config?.showJwtToken !== false && <>
                <hr/>
                <pre>JwtTokens: {JSON.stringify(jwtTokens, null, 2)}</pre>
            </>}
            <hr/>
            <pre>Apikey: {JSON.stringify(diagnostics, null, 2)}</pre>
            <hr/>
            Calling stats:
            <ul>
                <li>Displayed: {count}</li>
                <li>Set state: {localCount}</li>
                <li>Got details: {gotDetails}</li>
            </ul>
        </div>
    );
}
