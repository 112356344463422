import { TileDataInterface } from "../interfaces/TileInterface";
export const getCorrectedData = (
    favAppData: TileDataInterface[],
    alias: string,
    cat: string,
    seq: number
) => {
    const modifiedTiles: TileDataInterface[] = [];
    favAppData.forEach((obj) => {
        if (obj.alias !== alias) {
            let newSeq = obj.seq ?? 0;
            if (cat === obj.cat && obj.seq && obj.seq > seq) {
                newSeq = newSeq - 1;
            }
            modifiedTiles.push({ ...obj, seq: newSeq });
        }
    });
    return modifiedTiles;
};
