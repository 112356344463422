import { composeSearchFns, ifThenSearchFn, search, SearchFn } from "./search";
import { makeKnnQuery } from "./knn.search";
import { SearchContext } from "./searchContext";
import { makeKeywordsQuery } from "./keywords.search";
import { ESApiFinalResponseInterface } from "../interfaces/ElasticSearchInterface";
import { makeBoostAndPhraseQuery } from "./boostAndPhrase";
import { graphApiSearch } from "./graph.api.search";
import { jiraSearch } from "./jql.search.spec";
import {isM365} from "../components/el-search-advance/TabFilterStrategy";

//Here we have the possible search functions.
//in the index.tsx file we should decide which one to use and pass it to a SearchFnProvider

export function m365OrJiraOrAllSearch(
    context: SearchContext,
    graphApiToken: () => Promise<string>,
    jiraToken: () => Promise<string>
): SearchFn<ESApiFinalResponseInterface> {
    return ifThenSearchFn(
        (sr) => isM365(sr.searchIndexes),
        graphApiSearch(graphApiToken),
        ifThenSearchFn(
            (sr) => sr.searchIndexes.includes("jql"),
            jiraSearch(jiraToken),
            ifThenSearchFn(
                (sr) => sr.searchIndexes.includes("assistance"),
                noSearch(),
                boostAndPhraseSearch(context)
            )
        )
    );
}

function noSearch() : SearchFn<ESApiFinalResponseInterface> {
    return async () => [];
}

export function boostAndPhraseSearch(
    context: SearchContext
): SearchFn<ESApiFinalResponseInterface> {
    return search(
        context,
        "boostAndPhrase",
        makeBoostAndPhraseQuery(context.boosts || [])
    );
}

export function knnSearch(
    context: SearchContext
): SearchFn<ESApiFinalResponseInterface> {
    return search(context, "knn", makeKnnQuery);
}

export function keywordsSearch(
    context: SearchContext
): SearchFn<ESApiFinalResponseInterface> {
    return search(context, "keywords", makeKeywordsQuery);
}

export function hybridSearch(
    context: SearchContext
): SearchFn<ESApiFinalResponseInterface> {
    return composeSearchFns(
        (res) => `${res.index}/${res.id}`,
        keywordsSearch(context),
        knnSearch(context)
    );
}
