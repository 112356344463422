import {createContext, ReactNode, useCallback, useEffect, useState} from "react";
import useAiData, {IAiAssistData} from "./hooks/useAiData";
import {Message} from "../ai/ai.types";
import {AiClient, OutputAndSource, useAiWithRag} from "../ai/ai.rag.client";
import { useTranslation } from "react-i18next";

export interface MessageWithActionAndData extends Message {
    action?: string;
    data?: any;
}

interface IContextValues {
    handleChangeCurrentCardKey: (key: string) => () => void;
    currentCardKey: string;
    handleAiQuery: (query: string) => void;
    messages: MessageWithActionAndData[];
    isLoading: boolean;
    cards: IAiAssistData;
    handleResetChat: () => void;
    setMessages?: (messages: MessageWithActionAndData[]) => void;
    incidentText: string;
    setIncidentText: (value: string) => void;
    isIncidentTextPending: boolean;
    setIsIncidentTextPending: (value: boolean) => void;
}

const AiAssistContext = createContext<IContextValues>({
    handleChangeCurrentCardKey: () => () => {
    },
    currentCardKey: "",
    messages: [],
    handleAiQuery: () => {
    },
    isLoading: false,
    cards: {},
    handleResetChat: () => {
    },
    setMessages: () => {
    },
    incidentText: "",
    setIncidentText: () => {
    },
    isIncidentTextPending: true,
    setIsIncidentTextPending: () => {

    },
});

const AiAssistProvider = ({children}: { children: ReactNode }) => {
    const cards: IAiAssistData = useAiData();
    const [messages, setMessages] = useState<MessageWithActionAndData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentCardKey, setCurrentCardKey] = useState("selfService");
    const [incidentText, setIncidentText] = useState<string>("");
    const [isIncidentTextPending, setIsIncidentTextPending] = useState(true);
    const { language } = useTranslation().i18n;
    const ai: AiClient = useAiWithRag();

    const handleChangeCurrentCardKey = useCallback((key: string) => () => {
            setCurrentCardKey(key);
        }
        , [setCurrentCardKey]);

    const handleAiQuery = useCallback((query: string) => {
        if(query.trim().length > 0 ) {
            setMessages([...messages, {
                role: "user",
                action: "user-query",
                content: query,
            }]);
            setIsLoading(true);
            }
        }
        , [cards, currentCardKey, messages]);

    const handleAiAssist = useCallback(async (query: string) =>{
        const messagesWithoutLast = messages.slice(0, messages.length - 1);
            return await cards[currentCardKey].handleAiQuery(messagesWithoutLast, query);
        }
        , [cards, currentCardKey, messages]);

    useEffect(() => {
        if (messages.length === 0) return;

        const lastMessage = messages[messages.length - 1];

        const getResponse = async () => {
            const query = lastMessage.content;
            const response: OutputAndSource = await handleAiAssist(query);

            setMessages([...messages, {
                role: "assistant",
                content: response.output,
                action: response?.action,
                data: response?.data,
            }]);
            setIsLoading(false);
        };

        const getIncidentText = async () => {
            const response = await ai.aiClientSummariseChatHistoryToIncident(
                messages,
                "",
                language
            );
            setIncidentText(response.output);
            setIsIncidentTextPending(false);
        };

        if (lastMessage.role === "assistant") {
            getIncidentText();
        } else {
            getResponse().then(r => r);
        }
    }, [messages]);

    const handleResetChat = useCallback(() => setMessages([]), [setMessages]);

    return (
        <AiAssistContext.Provider
            value={{
                handleChangeCurrentCardKey,
                currentCardKey,
                handleAiQuery,
                messages,
                setMessages,
                isLoading,
                cards,
                handleResetChat,
                incidentText,
                setIncidentText,
                isIncidentTextPending,
                setIsIncidentTextPending,
            }}>
            {children}
        </AiAssistContext.Provider>
    );
};

export {AiAssistContext, AiAssistProvider};