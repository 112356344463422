import { EonUiInput } from "@eon-ui/eon-ui-components-react";
import { DropDownInfo } from "../../domain/info";
import { FilterSetters } from "./elsearch.domain";
import { DataSourceFilter } from "../../interfaces/ElasticSearchInterface";
import { useEffect, useState, useRef } from "react";

export type SearchInputProps = {
    searchTerm: string;
    setSearchTerm: (value: string) => void;
};

export function SearchInput({
    searchTerm,
    setSearchTerm,
}: Readonly<SearchInputProps>) {
    const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        setDebouncedTerm(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            setSearchTerm(debouncedTerm);
        }, 700);

        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, [debouncedTerm]);

    return (
        <EonUiInput
            type="text"
            trailingIconName="search"
            placeholder="Search..."
            value={debouncedTerm}
            onValueChanged={(e) => setDebouncedTerm(e.target.value)}
        />
    );
}

export type DataType =
    | ""
    | "all"
    | "apps"
    | "allWithFilter"
    | "m365"
    | "jql"
    | "assistance";

export type SelectDataTypeProps = {
    options: DropDownInfo[];
    dataType: DataType;
    setters: FilterSetters;
    dataSourceFilters: DataSourceFilter[];
};
