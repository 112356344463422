import React, { useEffect, useState } from "react";
import "./AvailabilityTimeline.scss"; // Add this for styling
import {
    ItemInterface,
    AvailabilityTimelineProps,
    ExistBookingInterface,
} from "../room-booking/RoomBookingInterface";

const AvailabilityTimeline: React.FC<AvailabilityTimelineProps> = ({
    unavailabilityInfo,
    interval = 60,
}) => {
    // Helper function to convert time to minutes since start of the day

    const [existBookings, setExistBookings] =
        useState<ExistBookingInterface[]>();

    console.log({ unavailabilityInfo });

    const timeHourMinute = (time?: string) => {
        return time?.slice(11, 16) ?? "";
    };

    useEffect(() => {
        const bookingList: ExistBookingInterface[] = [];
        unavailabilityInfo[0]?.items.map((range: ItemInterface) => {
            if (range.unavailability_type_id === 11) {
                // booking exist
                const existObj: ExistBookingInterface = {
                    start: timeHourMinute(range.begin?.datetime),
                    end: timeHourMinute(range.end?.datetime),
                };
                bookingList.push(existObj);
            }
            setExistBookings(bookingList);
        });
    }, [unavailabilityInfo]);

    const timeToMinutes = (time: string): number => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    };

    const minutesToTime = (minutes: number): string => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, "0")}:${mins
            .toString()
            .padStart(2, "0")}`;
    };

    const startMinutes = timeToMinutes(
        timeHourMinute(unavailabilityInfo[0]?.begin?.datetime)
    );
    const endMinutes = timeToMinutes(
        timeHourMinute(unavailabilityInfo[0]?.end?.datetime)
    );
    const totalMinutes = endMinutes - startMinutes;

    // Helper function to calculate the width percentage
    const calculateWidth = (start: number, end: number) => {
        return ((end - start) / totalMinutes) * 100;
    };

    const timeLabels = [];
    for (let time = startMinutes; time <= endMinutes; time += interval) {
        timeLabels.push(time);
    }

    return (
        <div className="timeline-wrapper">
            <div className="timeline-container">
                {existBookings?.map((booking, index) => {
                    const bookingStart = timeToMinutes(booking.start);
                    const bookingEnd = timeToMinutes(booking.end);
                    const bookingStartRelative =
                        Math.max(bookingStart, startMinutes) - startMinutes;
                    const bookingEndRelative =
                        Math.min(bookingEnd, endMinutes) - startMinutes;
                    const bookingWidth = calculateWidth(
                        bookingStartRelative,
                        bookingEndRelative
                    );

                    return (
                        <div
                            key={`${index.toString()}`}
                            title={`${booking.start} - ${booking.end}`}
                            className="booking-slot"
                            style={{
                                left: `${
                                    (bookingStartRelative / totalMinutes) * 100
                                }%`,
                                width: `${bookingWidth}%`,
                                backgroundColor: "red",
                            }}
                        />
                    );
                })}
                <div className="available-slot" style={{ width: "100%" }}></div>
            </div>
            <div className="time-scale">
                {timeLabels.map((time, index) => (
                    <div
                        key={`${index.toString()}`}
                        className="time-label"
                        style={{
                            left: `${
                                ((time - startMinutes) / totalMinutes) * 100
                            }%`,
                        }}
                    >
                        {minutesToTime(time)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AvailabilityTimeline;
