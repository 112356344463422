import { EonUiLink } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import { TabDataInterface } from "../../interfaces/TileInterface";
import "./Organize.scss";
import useSearchStore from "../../store/searchStore";

const Organize = ({
    isEditMode,
    favTabs,
}: {
    isEditMode: boolean;
    favTabs: TabDataInterface[];
}) => {
    const { showModal, toggleEditMode } = useSearchStore();
    const { t } = useTranslation();
    const activeTabObject = favTabs.find((obj) => obj.active);
    const activeTabTileCount = activeTabObject?.count;
    const tileCount = activeTabTileCount ?? 0;
    const organizeTileDisabled = tileCount < 2;
    const catTilesDisabled = isEditMode || favTabs.length < 2;

    const organizeTileClick = () => {
        if (!organizeTileDisabled) {
            toggleEditMode(!isEditMode);
        }
    };
    const catTileClick = () => {
        if (!catTilesDisabled) {
            showModal("personalizeDashboard");
        }
    };

    return (
        <div className="orgnize-wrapper">
            <EonUiLink
                icon="edit"
                iconPosition="left"
                id="tabTour2"
                onClick={organizeTileClick}
                title={t("moveTabAndTiles.organiseTilesTitle")}
                text={isEditMode ? t("cancel") : t("organiseTiles")}
                size="small"
                href="javascript:void(0)"
                disabled={organizeTileDisabled}
            ></EonUiLink>
            <EonUiLink
                icon="dashboard_02"
                iconPosition="left"
                id="tabTour3"
                disabled={catTilesDisabled}
                title={t("moveTabAndTiles.categorizeTilesTitle")}
                text={t("moveTabAndTiles.categorizeTiles")}
                size="small"
                href="javascript:void(0)"
                onClick={catTileClick}
            ></EonUiLink>
        </div>
    );
};

export default Organize;
