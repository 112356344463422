import React, {createContext, useState} from "react";

export type ExplainData = {
    explainDepth: number;
    full?:boolean
}
export type ExplainUsage = [ExplainData, (e: ExplainData) => void];

export const ExplainContext = createContext<ExplainUsage | undefined>(undefined);


export function ExplainProvider({children}: { children: any }) {
    const [data, setData] = useState<ExplainData>({explainDepth: 3});
    const usage: ExplainUsage = [data, setData];
    return <ExplainContext.Provider value={usage}>{children}</ExplainContext.Provider>;
}

export function useExplainData() {
    const usage = React.useContext(ExplainContext);
    if (!usage) throw new Error("useExplainData must be used within a ExplainProvider");
    return usage;
}