import {DropDownInfo, findDropDown} from "../../domain/info";
import React from "react";

export type FindTabFilterStrategy = (searchIndicies: string[]) => TabFilterStrategy

export function isM365(searchIndicies: string[]): boolean {
    const result = searchIndicies.find(si => si.includes('m365')) !== undefined;
    return result;
}

export function defaultFindTabFilterStrategy(searchIndicies: string[]): TabFilterStrategy {
    const m365 = isM365(searchIndicies);
    const listForFilters: ListForFiltersFn = () => findDropDown();
    const result: TabFilterStrategy = {listForFilters, searchIndicies, showNumbers: !m365};
    return result;
}

export const FindTabFilterContext = React.createContext<FindTabFilterStrategy>(defaultFindTabFilterStrategy);

export function useTabFilter(searchIndicies: string[]) {
    return React.useContext(FindTabFilterContext)(searchIndicies);
}

export type ListForFiltersFn = () => DropDownInfo[]
export type TabFilterStrategy = {
    listForFilters: ListForFiltersFn
    searchIndicies: string[]
    showNumbers?: boolean

}