import { useTranslation } from "react-i18next";
import "./SearchBooking.scss";
import {
    EonUiDatePicker,
    EonUiDropdown,
    EonUiDropdownOption,
    EonUiInput,
} from "@eon-ui/eon-ui-components-react";
import { EonUiInputCustomEvent } from "@eon-ui/eon-ui-components";
import useRoomBookingStore from "../../../store/roomBookingStore";
import { DATE_FILTER_MAX_GAP, MEETING_ROOM_TYPES, MeetingRoomType } from "../RoomBookingInterface";
import { getTimeInSeconds } from "../../../util/date";

const SearchBooking: React.FC = () => {
    const { t, i18n } = useTranslation("room-booking");
    const { searchType, fromDate, toDate, setSearchQuery, setSearchType, setFromDate, setToDate, searchQuery, invalidToDate, setInvalidToDate } = useRoomBookingStore();
    const meeting_room_type: MeetingRoomType[] = MEETING_ROOM_TYPES;

    const validateToDate = (fromDate: string, toDate: string) => {
        if (!fromDate || !toDate) return;
        const gap = (new Date(toDate).getTime() - new Date(fromDate).getTime()) / (1000 * 60 * 60 * 24);
        (fromDate > toDate || gap > DATE_FILTER_MAX_GAP) ? setInvalidToDate(true) : setInvalidToDate(false);
    };

    return (
        <div className="search-container">
            <div className="local-filters">
                <div className="free-text">
                    <EonUiInput
                        type="text"
                        size="small"
                        className="tab-input"
                        label={t("search")}
                        labelOutside
                        value={searchQuery}
                        placeholder={t("manageBooking.searchPlaceholder")}
                        onValueChanged={(e: EonUiInputCustomEvent<string>) =>
                            setSearchQuery(e.target.value)
                        }
                    />
                </div>
                <div className="booking-type">
                    <EonUiDropdown
                        placeholder="dropdown"
                        onValueChanged={(e) => setSearchType(e.target.value)}
                        onDropdownOpened={(e) => {
                            e.target.style.zIndex = getTimeInSeconds().toString();
                        }}
                        onDropdownClosed={(e) => {
                            e.target.style.removeProperty('z-index');
                        }}
                        value={searchType}
                        name="dropdown"
                        size="small"
                        label={t("type")}
                        labelOutside={true}
                    >
                        {meeting_room_type.map((val, index) => (
                            <EonUiDropdownOption
                                placeholder="option"
                                key={`${index.toString()}`}
                                value={val.name}
                                label={val.name}
                            />
                        ))}
                    </EonUiDropdown>
                </div>
            </div>
            <div className="date-filters">
                <div className="from_date">
                    <EonUiDatePicker
                        placeholder="datepicker"
                        onValueChanged={(e) => {
                            setFromDate(e.target.value);
                            validateToDate(e.target.value, toDate);
                        }}
                        onDatePickerOpened={(e) => {
                            const parentElement = e.target.parentElement;
                            if (parentElement) {
                                parentElement.style.zIndex = getTimeInSeconds().toString();
                            }
                        }}
                        onDatePickerClosed={(e) => {
                            const parentElement = e.target.parentElement;
                            if (parentElement) {
                                parentElement.style.removeProperty('z-index');
                            }
                        }}
                        value={fromDate}
                        name="from_date"
                        size="small"
                        label={t("from_date")}
                        labelOutside={true}
                        maxDaysBefore={0}
                        selectToday={true}
                        dateFormat="dd/MM/yyyy"
                        lang={i18n.language}
                        key={i18n.language}
                    />
                </div>
                <div className="to_date">
                    <EonUiDatePicker
                        placeholder="datepicker"
                        onValueChanged={(e) => {
                            setToDate(e.target.value);
                            validateToDate(fromDate, e.target.value);
                        }}
                        onDatePickerOpened={(e) => {
                            const parentElement = e.target.parentElement;
                            if (parentElement) {
                                parentElement.style.zIndex = getTimeInSeconds().toString();
                            }
                        }}
                        onDatePickerClosed={(e) => {
                            const parentElement = e.target.parentElement;
                            if (parentElement) {
                                parentElement.style.removeProperty('z-index');
                            }
                        }}
                        value={toDate}
                        name="to_date"
                        size="small"
                        label={t("to_date")}
                        labelOutside={true}
                        maxDaysBefore={0}
                        dateFormat="dd/MM/yyyy"
                        invalid={invalidToDate}
                        errorMessage={t("toDateError")}
                        lang={i18n.language}
                        key={i18n.language}
                    />
                </div>
            </div>
        </div>
    );
};

export default SearchBooking;
