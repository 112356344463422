import axios, {AxiosInstance, AxiosStatic} from "axios";

export type Message = {
    content: string;
    role: "user" | "system" | "assistant";
};

type THeaders = {
    "Content-Type": string;
    Authorization?: string;
    "x-proxy"?: string;
    "Api-Key"?: string;
    "Ocp-Apim-Subscription-Key"?: string;
        accept?: string;
}

export type AzureAiConfig = {
    axios: AxiosStatic;
    baseURL?: string;
    model?: string;
    customisation?: Record<string, any>;
    debug?: boolean;
    useApiKey?: boolean;
    apiVersion?: string;

};

export const defaultAzureAiConfig = (baseURL: string): AzureAiConfig => ({
    axios,
    baseURL,
    model: "gpt-4o-mini",
    apiVersion: "2024-02-01",
});

export interface TAzureAiClientConstructor {
    config: AzureAiConfig;
    tokenOrApiKey: string;
    useApiKey?: boolean
}

class AzureAiClientBase {
    protected axiosInstance: AxiosInstance;
    protected config: AzureAiConfig;
    protected tokenOrApiKey: string;
    protected useApiKey: boolean;
    protected aiModel: string;
    protected apiVersion: string;

    constructor({config, tokenOrApiKey, useApiKey}: TAzureAiClientConstructor) {
        this.config = config;
        this.tokenOrApiKey = tokenOrApiKey;
        this.useApiKey = useApiKey || false;
        this.axiosInstance = axios.create({ baseURL: config.baseURL });
        this.aiModel = config.model || "gpt-4o-mini";
        this.apiVersion = config.apiVersion || "2024-02-01";
    }

    protected getHeaders(): THeaders {
        const headers: THeaders = {
            "Content-Type": "application/json",
            accept: "*/*",
        };
        if (this.useApiKey) {
        headers["Api-Key"] = this.tokenOrApiKey;
        headers["Ocp-Apim-Subscription-Key"] = this.tokenOrApiKey;
        }
        if(!this.useApiKey) {
            headers["x-proxy"] = "azureai";
            headers["Authorization"] = `Bearer ${this.tokenOrApiKey}`;
        }
        return headers;
    }

    public async sendRequest(messages: Message[], otherConfig?: any): Promise<Message[]> {
        const headers = this.getHeaders();
        try {
            const response = await this.axiosInstance.post(
                `/openai/deployments/${this.aiModel}/chat/completions?api-version=${this.apiVersion}`,
                { messages, ...this.config.customisation, ...otherConfig },
                { headers }
            );
            return response.data.choices.map((x: any) => x.message);
        } catch (error) {
            console.error("Error calling openai:", messages, error);
            throw error;
        }
    }
}

export default AzureAiClientBase;
