let sessionId: string | undefined = undefined;

export const generateUUID = (): string => {
    return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
        /[018]/g,
        (c: any) =>
            (
                c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
    );
};
export const useSessionId = () => {
    if (!sessionId) sessionId = generateUUID();
    return sessionId;
};
