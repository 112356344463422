import { SelectedMetadataInterface } from "../../interfaces/ElasticSearchInterface";
import { createTermsQuery, emptyDataObject, SourceInfo } from "../info";

import {AGGREGATION_SIZE} from "../../config/OtherConfigs";

const index = "confluence-prod";

export const confluenceInfo: SourceInfo = {
    match: "confluence",
    dropDown: {
        label: "Confluence",
        value: index,
        icon: "confluence",
    },
    dateFilter: (index, { start, now }) => ({
        bool: {
            must: [
                { term: { _index: index } },
                {
                    range: {
                        last_updated: {
                            gte: start,
                            lte: now,
                            format: "yyyy-MM-dd",
                        },
                    },
                },
            ],
        },
    }),
    titleAndTarget: (result) => ({
        title: result?.title,
        target: result?.url,
        source: "Confluence",
    }),
    makeDataObject: (result) => ({
        ...emptyDataObject,
        id: result.id,
        index: result.index,
        icon: "confluence",
        link: result.url,
        name: result.title,
        source: "Confluence",
        desc: result.body,
        lastUpdate: result.last_updated,
        render: "html",
    }),
    restrictPdfTitles: true,
    refineFiltersAggregationQuery: {
        [index]: {
            filter: {
                term: {
                    _index: index,
                },
            },
            aggs: {
                space: {
                    terms: {
                        field: "space.keyword",
                        size: AGGREGATION_SIZE
                    },
                },
            },
        }
    },
    createMetadataFilters: (selectedMetadata: SelectedMetadataInterface): any => {
        const searchQuery = [];
        for (const key in selectedMetadata) {
            if (!selectedMetadata[key].length) continue;
            switch (key) {
                case "space": {
                    const termsQuery = createTermsQuery(selectedMetadata, key);
                    termsQuery && searchQuery.push(termsQuery);
                    break;
                }
                default:
                    return [];
            }
        }
        return searchQuery;
    },
};
