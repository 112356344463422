import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { EonUiLink, EonUiPreloaderSquares } from "@eon-ui/eon-ui-components-react";
import { AiAssistContext, MessageWithActionAndData } from "../AiAssist.context";
import { lookupTableAboutChatbotActions } from "../action-components/actions";
import userQueryComponent from "../action-components/userQuery.component";
import { getFrom } from "../../../util/map";
import AiWelcomeWrapper from "./AiWelcome";
import { Feedback } from "../../feedback/feedback.component";
import { ChatbotFeedbackPayload, LikeOrDislike } from "../../feedback/feedback";
import LiveAgentChat from "../../live-agent-chat/LiveAgentChat";
import useSearchStore from "../../../store/searchStore";
import QuickActionLinks from "../QuickActionLinks";
import SliderButton from "./SliderButton";
import { useTranslation } from "react-i18next";
import SVGIcon from "../../svg-icon/SVGIcon";
import { useScreenWidth } from "../../../context/ScreenWidthContext";

const Content: React.FC = () => {
    const { showLiveAgentChat } = useSearchStore();

    const { messages, isLoading, cards, currentCardKey } =
        useContext(AiAssistContext);

    const showFeedback = useMemo(
        () => messages[messages.length - 1]?.role === "assistant" && !isLoading,
        [messages, isLoading]
    );
    const showCreateIncident = useMemo(
        () => cards[currentCardKey]?.feedback?.includes("create-incident"),
        [cards, currentCardKey]
    );

    const feedbackDataHandler = useCallback(
        (like: LikeOrDislike): ChatbotFeedbackPayload => {
            return {
                like,
                source: "chatbot",
                messages,
                query: messages[messages.length - 1]?.content || "",
            };
        },
        [messages]
    );

    const messageComponents = useMemo(
        () =>
            messages.map((message: MessageWithActionAndData, index: number) => {
                switch (message.role) {
                    case "user": {
                        const UserComponent = userQueryComponent;
                        return (
                            <UserComponent key={`user-${index}`} {...message} />
                        );
                    }
                    case "assistant": {
                        const AssistantComponent = getFrom(
                            lookupTableAboutChatbotActions
                        )(message.action);
                        if (!AssistantComponent) {
                            console.error(
                                `No component found for action: ${message.action}`
                            );
                            return null;
                        }
                        return (
                            <AssistantComponent
                                key={`assistant-${index}`}
                                {...message}
                            />
                        );
                    }
                    default:
                        throw new Error("Invalid role");
                }
            }),
        [messages]
    );

    return (
        <>
            {messages.length <= 0 ? (
                <AiWelcomeWrapper />
            ) : (
                <div className="ai-assist-content-wrapper">
                    <div className="ai-assist-content">
                        {messageComponents}
                        {isLoading && (
                            <EonUiPreloaderSquares
                                className="preloader"
                                placeholder="preloader"
                            />
                        )}
                        {showFeedback && (
                            <Feedback
                                dataToSend={feedbackDataHandler}
                                showCreateIncident={showCreateIncident}
                            />
                        )}
                    </div>
                </div>
            )}
            {showLiveAgentChat && <LiveAgentChat />}
        </>
    );
};

const AiMessages: React.FC = () => {
    const { t } = useTranslation();
    const actionItemsList = [
        { type: "help", icon: "alert" },
        { type: "chat", icon: "messenger_01" },
        { type: "callback", icon: "talk_to_us" }
    ];
    const { messages } = useContext(AiAssistContext);
    const { isMobile } = useScreenWidth();
    const [showQuickLinks, setShowQuickLinks] = useState<boolean>(true);
    const toggleQuickActions = () => {
        setShowQuickLinks(!showQuickLinks);
    };

    useEffect(() => {
        setShowQuickLinks(!isMobile);
    }, [isMobile]);

    return (
        <div className="ai-assist-wrapper">
            <SliderButton />
            <div className="ai-assit-container" style={{ alignContent: messages.length ? 'normal' : 'center' }}>
                <Content />
            </div>
            <div className="ai-assist-right-panel" style={{display: "none"}}>
                <SliderButton />
                <div className="ai-assist-quick-action">
                    <div className="heading" onClick={toggleQuickActions}>
                        <SVGIcon className="icon" url={'./icons/quick_actions.svg'} />
                        <span>{t("chatBot.quickLinks.heading")}</span>
                        <EonUiLink
                            className="arrow-icon"
                            icon={showQuickLinks ? "chevron_bold_up" : "chevron_bold_down"}
                            iconPosition="right"
                            text=""
                            size="small"
                        ></EonUiLink>
                    </div>
                    <QuickActionLinks actionItems={actionItemsList} show={showQuickLinks} />
                </div>
            </div>
        </div>
    );
};

export default AiMessages;
