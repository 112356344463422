import {EonUiIcon, EonUiLink} from "@eon-ui/eon-ui-components-react";
import {t} from "i18next";
import {TSetShowAiAssist} from "../AiAssist";
import {useContext} from "react";
import {AiAssistContext} from "../AiAssist.context";
import useSearchStore from "../../../store/searchStore";

interface IAiHeaderProps {
    setShowAiAssist: TSetShowAiAssist;
}

const AiHeader = ({setShowAiAssist}: IAiHeaderProps) => {

    const {
        setShowLiveAgentChat
    } = useSearchStore();

    const { handleResetChat } = useContext(AiAssistContext);

    return (
        <div className="search-wrapper">
            <EonUiLink
                className="back-button"
                iconPosition="left"
                placeholder="back"
                icon="chevron_small_left"
                onClick={() => setShowAiAssist(false)}
                text={t("chatBot.searchBack")}
                title={t("chatBot.searchBack")}
                href="javascript:void(0)"
            ></EonUiLink>
            <button
                className="new-ai-chat"
                onClick={() => {
                    handleResetChat();
                    setShowLiveAgentChat(false);
                }}
                title={t("chatBot.newChat")}
            >
                <span className="icon-wrapper">
                    <EonUiIcon
                        placeholder="add"
                        className="margin-add eon-ui-svg-icon"
                        size="small"
                        name={"add"}
                        // scheme={
                        //     orgName === "bayernwerk" ? "neutral800" : "red500"
                        // }
                    ></EonUiIcon>
                </span>
                <span className="add-new-ai-chat">{t("chatBot.newChat")}</span>
            </button>
        </div>
    );
};

export default AiHeader;
