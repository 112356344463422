import { scroller } from "react-scroll";

export function scrollToClass(
    targetElement: string,
    defaultScrollLevel: number
) {
    if (document.getElementsByClassName(targetElement)) {
        scroller.scrollTo(targetElement, {
            duration: 200,
            delay: 200,
            smooth: true,
            container: "body",
            offset: defaultScrollLevel ? -72 : 0,
        });
    }
}

export function getParameterByName(parameterName: string) {
    let result: string | undefined;
    let tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach((item) => {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

export function sortArray<T>(list: T[], key: keyof T): T[] {
    return list.sort((a: T, b: T) => {
        if (a[key] > b[key]) {
            return 1;
        } else if (a[key] < b[key]) {
            return -1;
        } else {
            return 0;
        }
    });
}

export const isHigherEnv = () => {
    const getUrl = window.location.origin;
    const higherEnvUrls = [
        "https://pp-me.eon.tools",
        "https://me.eon.tools",
        "https://me.eon.com",
    ];
    return higherEnvUrls.indexOf(getUrl) > -1;
};

export function isLocalHost() {
    return window.location.origin.includes("localhost");
}

export function isDev() {
    return window.location.origin.match("dev-me8on");
}

export function isQA() {
    return window.location.origin.match("qa-me8on");
}
export function isPreProd(){
    return window.location.origin.match("pp-me");//https://pp-me.eon.tools/
}

export function envName(){
    if (isLocalHost()) return "local";
    if (isDev()) return "dev";
    if (isQA()) return "qa";
    if (isPreProd()) return "preprod";
    return "prod";
}

export function useDls() {
    // temp solution to enable dls always for dev environment or localhost
    return !!(isLocalHost() || isDev() || isQA());
}

export function isAppIndex(indexName: string) {
    const advancePageApps = indexName === "apps-prod";
    const tilePageApps = indexName.match(/app/);
    return advancePageApps || tilePageApps;
}

export function debounce(
    s: () => Promise<void>,
    delay: number = 500
): () => void {
    const delayDebounceFn = setTimeout(s, delay);
    return () => clearTimeout(delayDebounceFn);
}

export const withLoading = async <T>(
    setIsLoading: (isLoading: boolean) => void,
    fn: () => Promise<T>
): Promise<T> => {
    setIsLoading(true);
    try {
        return await fn();
    } finally {
        setIsLoading(false);
    }
};

export default {
    scrollToClass,
    sortArray,
    getParameterByName,
    isHigherEnv,
};
