import {
    TGenerateAiResponseFn,
    TGenerateAiResponseProps
} from "../ai.prompts";
import {getFrom, MapOrDefault} from "../../../util/map";

type AzureBlobSwedishSearchResult = {
    title: string;
    keyfield: string;
    chunk_type: "semantic_enriched" | "original_document"
    url: string;
}

export const auzreBlobRagPromptTemplates: MapOrDefault<TGenerateAiResponseFn<AzureBlobSwedishSearchResult>> = {
    "semantic_enriched": ({searchResult, language}) => ` 
            ## Matched Chunk:
            """ 
            ${searchResult.keyfield}
            """
            **CONTEXT**: You're company employee support agent. User asked a question that 
            went to the ElasticSearch and found matched chunk with general answer. 
            
            **INSTRUCTIONS**: 
            - See Matched Chunk. There might be the Answer to the user's question.
            - If Matched document includes URL, keep URL in the response.
            - Ensure the response is in natural language, accurate and concise.
            
            Last step- Respond in language: ${language} ${searchResult.url ? `and include the link to the azureblob document: ${searchResult.url}` : ""}`,

    "original_document": ({searchResult, language}) => ` Matched Document:
            ${searchResult.keyfield}
            
            CONTEXT: You're company employee support agent. User asked a question that 
            went to the ElasticSearch and found matched document with general answer. 
            
            INSTRUCTIONS: 
            1) See Matched Document. There is the content of the document.
            2) If Matched Document includes URL, keep URL in the response.
            3) Ensure the response is in natural language, accurate and concise.
            
            Last step- Respond in language: ${language} ${searchResult.url ? `and include the link to the azureblob document: ${searchResult.url}` : ""}`,
    default: ({language}) => `Respond that we don't have an answer for this question in ${language}.`
};

export const createAzureBlobSwedishRagPrompt =
    ({
         query,
         searchResult,
         language,
     }: TGenerateAiResponseProps<AzureBlobSwedishSearchResult>) => getFrom(auzreBlobRagPromptTemplates)(searchResult.chunk_type)({
        query,
        searchResult,
        language
    });