import {TGenerateAiResponseFn, TGenerateAiResponseProps} from "../ai.prompts";

export type OfficeBuddySearchResult = {
    t: { question: { [key: string]: string }, answer: { [key: string]: string } },
    keyfield: string;
    answer: string;
};

export const getPromptForOfficeBuddyProd: TGenerateAiResponseFn<OfficeBuddySearchResult> =
    ({
         searchResult,
         language,
     }: TGenerateAiResponseProps<OfficeBuddySearchResult>) => {
        return `
            ## Matched Q&A:
            **Question**: ${searchResult.keyfield}
            **Answer**: ${searchResult.answer}
            
            CONTEXT: You're company employee support agent. User asked a question that 
            went to the ElasticSearch and found matched document with general answer. 
            
            INSTRUCTIONS: 
            1) See Matched Q&A. There is the Answer to the user's question.
            2) IMPORTANT! Always if user question includes some personal conditions (for example: 
            Where is *my laptop*? Where is *my office*?) respond with general answer. 
            3) If Matched Q&A includes URL, keep URL in the response.
            4) Ensure the response is in natural language, accurate and concise.
            
            Last step- Respond in language: ${language}`;
    };
