import AzureAiClientBase, {Message} from "./ai.base.client";

export class SummariseToIncidentClientSingleton extends AzureAiClientBase {
    public async summariseToIncident(messages: Message[], reason: string, language: string) {
          const prompt = `Context: You have received conversation history of Enterprise Chatbot Assistant and User. User was unsatisfied with last assistant response as assistant couldn't help user to fix his issue and needs further IT helpdesk assistance. User has clicked "unsatisfied" button and then "Create incident".Then, Textbox has rendered, where should be issue description.

        Incident reason: ${reason}

        Instruction: 
        1) Take a look on latest message history. That is cleared conversation between user and chatbot.
        2) Imagine you're that user and you need to create incident based on what user has problem with.
        3) Generate Ticket description based on recent discussion and Incident reason.
        4) Ensure you provide only incident description without any additional content

        Example of expected returned text: 
        - I am experiencing....
        - I am unable to...
        - I have issue with...
        
        Last step: translate generated text to user's language: "${language}" and return it as response.
        `;
          const newMessages = [...messages, { role: "system", content: prompt }] as Message[];

        const outputMessages =  await this.sendRequest(newMessages);
            return {
                output: outputMessages[0].content,
                source: '',
                action: "summarise-to-incident",
            };
    }
}

export default SummariseToIncidentClientSingleton;
