import { useEffect } from "react";

const useClickOutside = (elementId: string, onClickOutside: () => void) => {
    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            const element = document.getElementById(elementId);
            if (element && !element.contains(event.target as Node)) {
                onClickOutside();
            }
        };
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [elementId, onClickOutside]);
};

export default useClickOutside;
