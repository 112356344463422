import { EonUiIcon } from "@eon-ui/eon-ui-components-react";
import "./AuthComponent.scss";
import { useAuth } from "../../MsalContext";
import { useTranslation } from "react-i18next";

function AuthComponent() {
    const { account, isExpired, login, logout } = useAuth();
    const { t } = useTranslation();
    const authTitle = account ? t("logOff") : t("logIn");
    return (
        <>
            {account && <div className="username">{account.name}</div>}
            <button
                className="auth-wrapper"
                id="tileTour4"
                onClick={() => {
                    account ? logout() : login();
                }}
                title={authTitle}
            >
                {isExpired && <div className="loggedout-alert"></div>}
                <EonUiIcon
                    title={authTitle}
                    size="normal"
                    name={account ? "logout" : "user_outline"}
                />
            </button>
        </>
    );
}

export default AuthComponent;
