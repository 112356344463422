import {TGenerateAiResponseFn, TGenerateAiResponseProps} from "../ai.prompts";

export type EdisConfluenceSearchResult = {
    t: { question: { [key: string]: string }, answer: { [key: string]: string } },
    summary: string,
    keyfield: string,
    answer: string,
    title: string,
    url: string,
}

export const getPromptForEdisConfluence: TGenerateAiResponseFn<EdisConfluenceSearchResult> =
    ({
         searchResult,
         language,
     }: TGenerateAiResponseProps<EdisConfluenceSearchResult>) => {
        return ` Matched Q&A:
            Question: ${searchResult.keyfield}
            Answer: ${searchResult.answer}
                                
            -- START DOCUMENT --
             ${(searchResult as any)["summary"]}    
            -- END DOCUMENT --
                
            CONTEXT: You're company employee support agent. User asked a question that 
            went to the ElasticSearch and found matched Q&A with content. Q&A is returned 
            from ElasticSearch has been built using custom "Questioner" app based on the input 
            Confluence Documents (like connector). So it's based on Confluence page.

            INSTRUCTIONS:
            1) See Matched Q&A. There is the Answer to the user's question.
            2) If Matched Q&A includes URL, keep URL in the response.
            3) Review the Full document content and include it in the response if needed.
            3a) If document has information in points, you can also response in points.
            3b) If document has URL, keep URL in the response.
            4) Ensure the response is in natural language, accurate and concise.
            
            Last step- Respond in language: "${language}" ${searchResult.url ? `and include the link to the confluence page: ${searchResult.url}`: ""}`;
    };