import { ReactComponent as AiIcon } from "../../assets/image/ai-icon32.svg";
import "./AiButton.scss";

interface AiButtonProps {
    isEditMode?: boolean;
    aiToggle?: (value?: boolean) => void;
    title?: string;
}

const AiButton = ({isEditMode, aiToggle, title}: AiButtonProps) => {
    return (
        <button
            className="ai-button-wrapper"
            onClick={() => (!isEditMode && aiToggle ? aiToggle(true) : null)}
            title={title}
        >
            <AiIcon />
            <div
                id="tileTour5"
                data-testid="about-me8eon"
                className="ai-button-label"
                // onClick={() => setShowAiAssist(true)}
            >
                Assistance
            </div>
        </button>
    );
};

export default AiButton;
