import { TileDataInterface } from "../interfaces/TileInterface";

export const collectedApps: TileDataInterface[] = [
    {
        id: 1,
        alias: "time",
        title: "Time-Tracking",
        subTitle: "CATS",
        target: "https://peo.apps.eon.com/sap/bc/ui5_ui5/ui2/ushell/shells/abap/FioriLaunchpad.html#xEONx_CATS-manage",
        icon: "clock_alt",
        status: false,
        index: "search_app",
    },
    {
        id: 2,
        alias: "meeting",
        title: "Meeting Room Booking",
        subTitle: "GINGCO",
        target: "/roombooking",
        icon: "calendar",
        status: false,
        index: "search_app",
    },
    {
        id: 3,
        alias: "approval",
        title: "My Approvals",
        subTitle: "SNOW/SAP",
        target: "/my-approvals",
        icon: "all_in_one_alt",
        status: false,
        index: "search_app",
    },
    {
        id: 4,
        alias: "pr",
        title: "Create Purchase Requisition",
        subTitle: "BANF",
        target: "https://peo.apps.eon.com/sap/bc/ui5_ui5/ui2/ushell/shells/abap/FioriLaunchpad.html#xEONxMM_REQ-create?mode=create&/Search",
        icon: "pages",
        status: false,
        index: "search_app",
    },
    {
        id: 5,
        alias: "pra",
        title: "Create Purchase Requisition Advanced",
        subTitle: "BANF",
        target: "https://peo.apps.eon.com/sap/bc/ui5_ui5/ui2/ushell/shells/abap/FioriLaunchpad.html#PurchaseRequisition-create?sap-ui-tech-hint=GUI&/h4screen=advanced",
        icon: "shopping_cart",
        status: false,
        index: "search_app",
    },
    {
        id: 6,
        alias: "processing",
        title: "My Inbox",
        subTitle: "SAP",
        target: "https://peo.apps.eon.com/sap/bc/ui5_ui5/ui2/ushell/shells/abap/FioriLaunchpad.html#WorkflowTask-displayInbox?allItems=true",
        icon: "all_in_one_alt",
        status: false,
        index: "search_app",
    },
    {
        id: 7,
        alias: "hr",
        title: "HR Portal",
        subTitle: "Connect",
        target: "https://hr.portal.eon.com/irj/portal/hr",
        icon: "user_group_alt",
        status: false,
        index: "search_app",
    },
    {
        id: 8,
        alias: "concur",
        title: "Concur Travel Booking and Expenses",
        subTitle: "SAP Concur",
        target: "https://eu2.concursolutions.com/home.asp",
        icon: "reload",
        status: false,
        index: "search_app",
    },
    {
        id: 9,
        alias: "password",
        title: "SAP Password Reset",
        subTitle: "SAP Systems",
        target: "https://pgr.apps.eon.com/sap/bc/ui2/flp?sap-client=100&sap-language=EN#AccessUser-restPassword?sap-ui-tech-hint=WDA",
        icon: "lock",
        status: false,
        index: "search_app",
    },
    {
        id: 10,
        alias: "ariba",
        title: "Ariba - Classic and Classic+",
        subTitle: "PO Overview",
        target: "https://qeo.apps.eon.com/sap/bc/ui5_ui5/ui2/ushell/shells/abap/FioriLaunchpad.html#xEONxMM_ARB_PO-overview",
        icon: "shopping_cart_add",
        status: false,
        index: "search_app",
    },
    {
        id: 11,
        alias: "itresources",
        title: "IT Resources",
        subTitle: "SNOW",
        target: "/it-resources",
        icon: "shopping_cart_add",
        status: false,
        index: "search_app",
    },
    {
        id: 12,
        alias: "order",
        title: "Order",
        subTitle: "Order IT Services",
        target: "/orders",
        icon: "thumb_up",
        status: false,
        index: "search_app",
    },
    {
        id: 13,
        alias: "incident",
        title: "Incidents",
        subTitle: "SNOW",
        target: "/incidents",
        icon: "shopping_cart_add",
        status: false,
        index: "search_app",
    },
    {
        id: 14,
        alias: "myassets",
        title: "My Assets",
        subTitle: "SNOW",
        target: "/it-resources",
        icon: "shopping_cart_add",
        status: false,
        index: "search_app",
    },
    {
        id: 15,
        alias: "beeline",
        title: "Beeline",
        subTitle: "External Hiring ",
        target: "https://prod-eu.beeline.com/eon/",
        icon: "thumb_up",
        status: false,
        index: "search_app",
    },
    {
        id: 16,
        alias: "prisma",
        title: "Prisma",
        subTitle: "HSE",
        target: "https://eon.credit360.com/csr/site/portal/Home.acds",
        icon: "user_group_alt",
        status: false,
        index: "search_app",
    },
];
