import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    myApprovalCount,
    getAccessToken,
    getAuthorizationUrl,
} from "../../services/myApproval";

function MyApprovalCount() {
    const [count, setCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            setLoading(true);
            const token = await getAccessToken();
            if (!token) {
                const authUrl = getAuthorizationUrl();
                if (authUrl) {
                    window.location.href = authUrl;
                }
            }
            if (token) {
                const { hello } = await myApprovalCount({
                    access_token: token,
                });
                if (hello) {
                    setCount(hello);
                    setLoading(false);
                }
            }
        })();
    }, []);
    return loading ? (
        <></>
    ) : (
        <div className="tile-numbers">
            <strong>{count} </strong> {t("tilePage.myInboxCountSuffix")}
        </div>
    );
}

export default MyApprovalCount;
