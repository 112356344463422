import ApiCall from "./ApiCall";

export const getUserBookingApi = async(
    payload: any
): Promise<any> => {
    console.log("Get User Booking", payload);
    return await ApiCall(
        "api/gingco/GetUserBookings",
        "post",
        payload
    );
};

export const getBookingContainerTemplateApi = async(
    payload: any
): Promise<any> => {
    console.log("Get Booking Container", payload);
    return await ApiCall(
        "api/gingco/GetBookingContainerTemplates",
        "post",
        payload
    );
};

export const updateBookingApi = async(
    payload: any
): Promise<any> => {
    console.log("Update Booking", payload);
    return await ApiCall(
        "api/gingco/updateBookings",
        "post",
        payload
    );
};

export const cancelBookingApi = async(
    payload: any
): Promise<any> => {
    console.log("Cancel Booking", payload);
    return await ApiCall(
        "api/gingco/CancelBookings",
        "post",
        payload
    );
};

export const getTimeLinesApi = async(
    payload: any
): Promise<any> => {
    console.log("Get TimeLines", payload);
    return await ApiCall(
        "api/gingco/GetTimeLines",
        "post",
        payload
    );
};

export const saveBookingsApi = async(
    payload: any
): Promise<any> => {
    console.log("Save Booking", payload);
    return await ApiCall(
        "api/gingco/SaveBookings",
        "post",
        payload
    );
};

export const getBookableLocationsApi = async(
    payload: any
): Promise<any> => {
    return await ApiCall(
        "api/gingco/GetBookableLocations",
        "post",
        payload
    );
};
