import {DataObject, emptyDataObject, SourceInfo} from "../info";

const oneDriveParts =[ 'personal', 'me'];
export const m365Info: SourceInfo = {
    match: "m365",
    dropDown: undefined,
    titleAndTarget: (result: any) => ({
        title: `someTitle ${JSON.stringify(result)}`,
        target: result?.target,
        source: undefined,
    }),
    makeDataObject: (c: any) => {
        const url = c.resource.webUrl;
        const parts = url.split("/");
        const thirdPart = parts[3];
        const icon = oneDriveParts.includes(thirdPart) ? 'onedrive' : 'sharepoint';
        const result: DataObject = {
            ...emptyDataObject,
            id: c.resource.id,
            icon,
            name: c.resource.name,
            link: `${url}?web=1`,
            createdAt: c.resource.createdDateTime,
            lastUpdate: c.resource.lastModifiedDateTime,
            index: "m365",
            render: "html",
            desc: c.summary,
        };
        return result;
    },
};
