import { EonUiButton, EonUiLink } from "@eon-ui/eon-ui-components-react";
import React, { useState, useEffect } from "react";
import { marked } from "marked";
import "./TypingEffect.scss";
import { useTranslation } from "react-i18next";
import { openPdfInNewTab, getBlobUrl } from "../openPdfInTab";
import { useJwtTokens } from "../../../diagnostics/diagnostics";
import {EnvironmentConfig} from "../../../../config/EnvironmentConfig";
import LoaderComponent from "../../../loader-component/LoaderComponent";
import { parsedLinkArray } from "../parseLinks";

interface ITypingEffectProps {
    text: string;
    speed: number;
    effecttEnd?: () => void;
    className: string;
}
type Link = {
    url: string;
    text: string | null;
};

const TypingEffect: React.FC<ITypingEffectProps> = ({
    text,
    speed,
    effecttEnd,
    className,
}) => {
    const [displayText, setDisplayText] = useState("");
    const [linksArray, setLinksArray] = useState<Link[]>([]);
    const [textRendered, setTextRenderered] = useState<boolean>(false);
    const [showPreview, setShowPreview] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const jwt = useJwtTokens().token || "";

    useEffect(() => {
        let currentIndex = -1;
        const interval = setInterval(() => {
            setDisplayText((prevText) => {
                if (currentIndex < text.length) {
                    if (currentIndex < 0) {
                        return text[0];
                    } else {
                        if (!text[currentIndex + 1]) {
                            setTextRenderered(true);
                        }
                        return prevText + text[currentIndex];
                    }
                } else {
                    clearInterval(interval);
                    effecttEnd && effecttEnd();
                    return prevText;
                }
            });
            currentIndex = currentIndex + 1;
        }, speed);
        return () => clearInterval(interval);
    }, [text, speed]);

    useEffect(() => {
        setLinksArray(parsedLinkArray(text));
    }, [text]);

    const handleCitationUrl = async (url: string) => {
        const isPdf =
            url.match("snowkbforme8eon") ||
            url.match("genai") ||
            url.match(".pdf");
        if (window.innerWidth > 920 && isPdf) {
            setIsLoading(true);
            const blobUrl = isGingoPdfApi(url)
                ? await getBlobUrl(url, jwt)
                : url;
            setIsLoading(false);
            if (showPreview === blobUrl) {
                setShowPreview("");
                return;
            }
            setShowPreview(blobUrl);
        } else if (isGingoPdfApi(url)) {
            setIsLoading(true);
            await openPdfInNewTab(url, jwt);
            setIsLoading(false);
        } else {
            window.open(url, "_blank");
        }
    };

    const isGingoPdfApi = (url: string): boolean => {
        return (
            url.startsWith(EnvironmentConfig.genAiApiBaseUrl as string) &&
            url.split(/[?#]/)[0].endsWith(".pdf")
        );
    };

    const filteredLinksArray = () => {
        return linksArray.filter((link) => !isGingoPdfApi(link.url));
    };

    return (
        <>
            {filteredLinksArray().length > 0 && (
                <div className="link-section">
                    <div className="link-heading">
                        <span className="">Link</span>
                    </div>

                    {filteredLinksArray().map((link, index) => (
                        <div key={`link_${index + 1}`} className="link-wrapper">
                            <div className="link">
                                <a
                                    href={link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {link.url.split(/[?#]/)[0]}
                                </a>
                            </div>
                            <div className="link-buttons">
                                <EonUiLink
                                    icon="copy"
                                    onClick={() => {
                                        navigator.clipboard.writeText(link.url);
                                    }}
                                    title={t("chatBot.copyLink")}
                                    href="javascript:void(0)"
                                />
                                <EonUiLink
                                    icon="link_external"
                                    target="_blank"
                                    title={t("chatBot.openLink")}
                                    href={link.url}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <div
                className={`ai-response ${className}`}
                dangerouslySetInnerHTML={{
                    __html: marked.parseInline(displayText) as string,
                }}
            />
            <LoaderComponent isLoading={isLoading} />
            <div className={`${showPreview ? "iframe-with-links" : ""}`}>
                {textRendered && linksArray && linksArray.length > 0 && (
                    <div className="citation-container">
                        {linksArray.map((link, index) => (
                            <EonUiButton
                                size="small"
                                rank="secondary"
                                text={link.text ?? link.url}
                                onClick={() => handleCitationUrl(link.url)}
                                key={`button_${index + 1}`}
                                className="citation-item"
                            ></EonUiButton>
                        ))}
                    </div>
                )}
                {showPreview && (
                    <iframe
                        className="pdf-preview"
                        title="internal-documents"
                        src={showPreview}
                    />
                )}
            </div>
        </>
    );
};

export default TypingEffect;
