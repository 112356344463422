import { getMSALAccountKeys, getSnowBearerToken } from "./localstorage";

function getKID() {
    const msalIdData = getMSALAccountKeys();
    if (!msalIdData) {
        throw Error("MSAL account keys not found");
    }

    try {
        const msalID =
            JSON.parse(msalIdData).length && JSON.parse(msalIdData)[0];
        const temp = localStorage.getItem(msalID);
        if (!temp) {
            throw Error("MSAL data not available");
        }
        const userName = JSON.parse(temp).username;
        return userName.split("@")[0];
    } catch (error) {
        console.error("getKID", error);
        throw error;
    }
}

function getAuthHeader() {
    return {
        kid: getKID(),
        Authorization: `Bearer ${getSnowBearerToken()}`,
        "Content-Type": "application/json",
    };
}

export { getKID, getAuthHeader };
