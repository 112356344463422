function getMSALAccountKeys() {
    return localStorage.getItem("msal.account.keys");
}

function getSnowBearerToken() {
    return localStorage.getItem("snowBearerToken") ?? "";
}

function getSnowBearerTokenLiveAgentChat() {
    return localStorage.getItem("snowBearerTokenLiveAgentChat") ?? "";
}

export { getMSALAccountKeys, getSnowBearerToken, getSnowBearerTokenLiveAgentChat };
