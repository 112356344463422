import {useAuth} from "../../../MsalContext";

const useFirstName = () => {
    const {account} = useAuth();
    const nameArray = account?.name?.split(",") ?? [];
    const firstName = nameArray[1] || nameArray[0];
    return {
        firstName
    };
};

export default useFirstName;