import { t } from "i18next";
import {
    EonUiIcon,
    EonUiInput,
    EonUiPreloaderSquares,
    EonUiTagPill,
    EonUiTagPillGroup,
} from "@eon-ui/eon-ui-components-react";
import { useCallback, useContext, useState } from "react";
import { raiseIncident } from "../../services/raiseIncident";
import { AiAssistContext } from "../ai-assist-refactored/AiAssist.context";
import { ReactComponent as AiIcon } from "../../assets/image/ai-icon32.svg";

interface IFeedbackCreateIncidentComponentProps {
    setIsCreateIncidentOpen: (isOpen: boolean) => void;
}

const FeedbackCreateIncidentComponent = ({
    setIsCreateIncidentOpen,
}: IFeedbackCreateIncidentComponentProps) => {
    const [isEditIncident, setIsEditIncident] = useState(false);
    const [confirmTicketCreation, setConfirmTicketCreation] = useState(false);
    const { isIncidentTextPending, incidentText, setIncidentText } = useContext(AiAssistContext);
    const [incidentResponse, setIncidentResponse] = useState<any>();
    const [respLoading, setRespLoading] = useState(false);

    const handleSetIncidentText = useCallback(
        (text: string) => {
            setIncidentText(text);
        },
        [setIncidentText]
    );

    const createIncidentApi = async (message: string) => {
        setRespLoading(true);
        setIsEditIncident(false);
        try {
            const incidentResponse = await raiseIncident(message);
            setIncidentResponse(incidentResponse);
            setConfirmTicketCreation(true);
            setRespLoading(false);
        } catch (popupError) {
            setRespLoading(false);
            console.error(
                "Something went wrong. Please try again later.",
                popupError
            );
        }
    };

    return (
        <div className="bot-header">
            <div className="eon-logo">
                <AiIcon />
            </div>
            <div className="bot-reply-container">
                <span className="bot-heading">
                    {t("chatBot.creatingIncident")}
                </span>
                <div className="ticket-container">
                    <div className="ticket-description">
                        <span className="">
                            {t("chatBot.ticketDescription")}
                        </span>
                    </div>
                    <div className="message-content">
                        {isIncidentTextPending ? (
                            <EonUiPreloaderSquares
                                className="preloader"
                                placeholder="preloader"
                            />
                        ) : isEditIncident ? (
                            <EonUiInput
                                value={incidentText}
                                size="normal"
                                rows={5}
                                textarea
                                onValueChanged={(e) =>
                                    handleSetIncidentText(e.target.value)
                                }
                            />
                        ) : (
                            <div>{incidentText}</div>
                        )}
                    </div>
                    {!confirmTicketCreation && (
                        <div className="buttons-section">
                            <EonUiTagPillGroup>
                                <EonUiTagPill
                                    text={
                                        isEditIncident
                                            ? t("chatBot.createIncident")
                                            : t("chatBot.confirmCreateIncident")
                                    }
                                    class="feedback-tag"
                                    scheme="neutral800"
                                    onClick={() =>
                                        createIncidentApi(incidentText)
                                    }
                                ></EonUiTagPill>
                                {!isEditIncident && (
                                    <EonUiTagPill
                                        text={t("chatBot.editCreateIncident")}
                                        onClick={() => setIsEditIncident(true)}
                                        scheme="neutral800"
                                    ></EonUiTagPill>
                                )}
                                <EonUiTagPill
                                    text={t("cancel")}
                                    scheme="neutral800"
                                    onClick={() =>
                                        setIsCreateIncidentOpen(false)
                                    }
                                ></EonUiTagPill>
                            </EonUiTagPillGroup>
                        </div>
                    )}
                    {respLoading && (
                        <div className="loading">
                            <EonUiPreloaderSquares
                                className="preloader"
                                placeholder="preloader"
                            />
                        </div>
                    )}
                    {confirmTicketCreation && incidentResponse?.number ? (
                        <div className="ticket-success">
                            <EonUiIcon
                                className="user-icon eon-ui-svg-icon"
                                name="radio_checkbox_tick"
                                placeholder="radio_checkbox_tick"
                                scheme="red500"
                            />
                            <p>
                                {t("chatBot.ticketSuccessMsg")}
                                {incidentResponse?.number}
                            </p>
                        </div>
                    ) : (
                        confirmTicketCreation && (
                            <p className="failure-msg">
                                {t("chatBot.ticketErrorMsg")}
                            </p>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default FeedbackCreateIncidentComponent;
