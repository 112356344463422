import axios from "axios";
import pathResolver from "./PathResolver";

export default function ApiCall(
    apiName: string,
    methodType: string,
    payLoad: string | object
) {
    const isGingco = apiName.includes("gingco");
    const url = pathResolver(
        apiName,
        isGingco,
        typeof payLoad === "string" ? payLoad : ""
    );
    const headers = {
        Accept: "application/json",
        "Content-type": "application/json;utf-8",
        "Access-Control-Allow-Origin": "*",
    };

    return axios({
        method: methodType,
        url,
        data: typeof payLoad === "object" ? JSON.stringify(payLoad) : "{}",
        headers,
    })
        .then((response) => {
            console.log("success response");
            return response ? response.data : false;
        })
        .catch((error) => {
            console.error("Fetch Error :-S", error);
            return false;
        });
}
